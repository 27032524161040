import React, { useEffect }  from "react"
import styled from 'styled-components'

import PaperPencil from '../../svgs/paper-and-pencil.svg'
import ClipBoard from '../../svgs/clipboards.svg'
import BarGraph from '../../svgs/bar-graph.svg'
import AbTesting from '../../svgs/a-b-testing.svg'
import Target from '../../svgs/target.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const CircleRow = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({

            "(min-width: 1200px)": function() {
                // circle closing transition to next section
                let tlCircleOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_six_animation",
                        start: '160px 80%',
                        end: '160px 80%',
                        id: 'circle_close',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlCircleOne.to('.ec-red-box', { duration: .5, opacity: '1' }, 0)
                tlCircleOne.to('.circle-box.dim-box-8', { duration: .5, opacity: '0' }, 0.5)
                //tlCircleOne.to('.circle-box', { duration: .5, opacity: '0' }, 0.5)
                tlCircleOne.to('.ec-red-box', { duration: .5, ease: "power1.inOut", width: '50px', height: '50px', bottom: '0px', right: '0px', borderRadius: '0%', rotation: 180  })
                tlCircleOne.to('.ec-header', { duration: .1, opacity: '1' })
                tlCircleOne.to('.ec-red-box', { duration: .1, opacity: '0'})
                tlCircleOne.add("EcLabel")
                tlCircleOne.to('.ec-header', { maxHeight: '500px', maxWidth: '704px', padding: '50px 55px', border: '10px solid #db4140' }, "EcLabel" )
                tlCircleOne.to('.ec-content', { duration: .5, opacity: '1', top: '0px' }, "EcLabel" )
            }

        });

    }, []);

    return(
        <MainRow id="circle_close" className="circle-row-mobile">
            <div class="circle-background">
                <div class="bg-circle-1 bg-circle animation-float-big-reverse"></div>
                <div class="bg-circle-2 bg-circle animation-float-small"></div>
                <div class="bg-circle-3 bg-circle animation-float-medium"></div>
                <div class="bg-circle-4 bg-circle animation-float-big"></div>
                <div class="bg-circle-5 bg-circle animation-float-small-reverse"></div>
            </div>
            <div class="circle-box dim-box-1">
                <div class="circle-1 custom-circle">
                    <div class="circle-content">
                        <div class="circle-icon paper-pencil">
                            <PaperPencil/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-2">
                <div class="circle-2 custom-circle">
                    <div class="circle-content">
                        <div class="circle-icon clipboards">
                            <ClipBoard/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-3">
                <div class="circle-3 custom-circle">
                    <div class="circle-content">
                        <h4>Paid Media Planning</h4>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-4">
                <div class="circle-4 custom-circle">
                    <div class="circle-content">
                        <h4>Messaging & Content Creation</h4>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-5">
                <div class="circle-5 custom-circle">
                    <div class="circle-content">
                        <h4>Custom Metrics Reporting</h4>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-6">
                <div class="circle-6 custom-circle">
                    <div class="circle-content">
                        <div class="circle-icon bar-graph">
                            <BarGraph/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-7">
                <div class="circle-7 custom-circle">
                    <div class="circle-content">
                        <div class="circle-icon a-b-testing">
                            <AbTesting/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-8">
                <div class="circle-8 custom-circle">
                    <div class="circle-content">
                        <h4>Content Testing & Optimization</h4>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-9">
                <div class="circle-9 custom-circle">
                    <div class="circle-content">
                        <div class="circle-icon target-icon">
                            <Target/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-box dim-box-10">
                <div class="circle-10 custom-circle">
                    <div class="circle-content">
                        <h4>Search (SEO/SEM)</h4>
                    </div>
                </div>
            </div>
        </MainRow>
    );

}

const MainRow = styled.div`
    max-width: 1270px;
    width: 100%;
    height: 700px;
    padding: 0;
    margin: 0 auto;
    position: relative;
    .circle-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: -50px;
        left: 0;
        .bg-circle {
            opacity: 0;
        }
        .bg-circle-1 {
            position: absolute;
            border: 5px solid #eaeaea;
            border-radius: 50%;
            width: 450px;
            height: 450px;
            top: -30px;
            left: 50px;
        }
        .bg-circle-2 {
            position: absolute;
            border: 5px solid #eaeaea;
            border-radius: 50%;
            width: 250px;
            height: 250px;
            top: 480px;
            left: 80px;
        }
        .bg-circle-3 {
            position: absolute;
            border: 5px solid #eaeaea;
            border-radius: 50%;
            width: 350px;
            height: 350px;
            top: 280px;
            left: 455px;
        }
        .bg-circle-4 {
            position: absolute;
            border: 5px solid #eaeaea;
            border-radius: 50%;
            width: 250px;
            height: 250px;
            top: 74px;
            left: 757px;
        }
        .bg-circle-5 {
            position: absolute;
            border: 5px solid #eaeaea;
            border-radius: 50%;
            width: 300px;
            height: 300px;
            top: 315px;
            left: 878px;
        }
    }
    .circle-box {
        position: absolute;
        transform: scale(0);
        opacity: 0;
        &.dim-box-1 {
            width: 180px;
            height: 180px;
            top: 40px;
            left: 360px;
        }
        &.dim-box-2 {
            width: 180px;
            height: 180px;
            top: 260px;
            left: 15px;
        }
        &.dim-box-3 {
            width: 210px;
            height: 210px;
            top: 210px;
            left: 225px;
        }
        &.dim-box-4 {
            width: 270px;
            height: 270px;
            top: 145px;
            left: 527px;
        }
        &.dim-box-5 {
            width: 280px;
            height: 280px;
            top: 200px;
            left: 817px;
        }
        &.dim-box-6 {
            width: 180px;
            height: 180px;
            top: 381px;
            left: 1080px;
        }
        &.dim-box-7 {
            width: 180px;
            height: 180px;
            top: 425px;
            left: 180px;
        }
        &.dim-box-8 {
            width: 270px;
            height: 270px;
            top: 400px;
            left: 380px;
        }
        &.dim-box-9 {
            width: 180px;
            height: 180px;
            top: 455px;
            left: 690px;
        }
        &.dim-box-10 {
            width: 180px;
            height: 180px;
            top: 487px;
            left: 890px;
        }
    }
    .custom-circle {
        position: relative;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: #2b2520;
        display: flex;
        justify-content: center;
        align-items: center;
        &.circle-1,
        &.circle-5,
        &.circle-7,
        &.circle-8,
        &.circle-9 {
            background-color: #db4140;
        }
        &.circle-1 {
            width: 180px;
            height: 180px;
            margin-left: 1px;
        }
        &.circle-2 {
            width: 180px;
            height: 180px;
            margin-left: 1px;
        }
        &.circle-3 {
            width: 210px;
            height: 210px;
            margin-left: 1px;
        }
        &.circle-4 {
            width: 270px;
            height: 270px;
            margin-left: 1px;
        }
        .circle-content {
            padding: 0 30px;
            .circle-icon {
                margin: 0 auto;
            }
            .paper-pencil {
                width: 89px;
                height: 89px;
            }
            .clipboards {
                height: 89px;
                width: 68px;
            }
            .bar-graph {
                height: 86px;
                width: 102px;
            }
            .a-b-testing {
                height: 80px;
                width: 85px;
            }
            .target-icon {
                height: 101px;
                width: 101px;
            }
            h4 {
                text-align: center;
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #fff;
                margin-top: 0px;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
    }
    @media(max-width:1200px) {
        transform: scale(.7);
        margin-top: -50px;
        opacity: 0;
        .circle-background {
            .bg-circle {
                opacity: 1 !important;
            }
        }
        .circle-box {
            transform: scale(1) !important;
            opacity: 1 !important;
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 500px;
        height: 550px;
        .circle-background {
            .bg-circle-1 {
                width: 250px;
                height: 250px;
                top: 50px;
                left: 20px;
            }
            .bg-circle-2 {
                width: 180px;
                height: 180px;
                top: 400px;
                left: 0;
            }
            .bg-circle-3 {
                width: 200px;
                height: 200px;
                top: 280px;
                left: 300px;
            }
            .bg-circle-4,
            .bg-circle-5 {
                display: none;
            }
        }
        .circle-box {
            &.dim-box-1 {
                width: 100px;
                height: 100px;
                top: 0;
                left: 150px;
            }
            &.dim-box-2 {
                width: 100px;
                height: 100px;
                top: 60px;
                left: 30px;
            }
            &.dim-box-3 {
                width: 140px;
                height: 140px;
                top: 123px;
                left: 175px;
            }
            &.dim-box-4 {
                width: 145px;
                height: 145px;
                top: 30px;
                left: 331px;
            }
            &.dim-box-5 {
                width: 145px;
                height: 145px;
                top: 298px;
                left: 328px;
            }
            &.dim-box-6 {
                width: 100px;
                height: 100px;
                top: 450px;
                left: 200px;
            }
            &.dim-box-7 {
                width: 100px;
                height: 100px;
                top: 250px;
                left: 40px;
            }
            &.dim-box-8 {
                width: 140px;
                height: 140px;
                top: 283px;
                left: 164px;
            }
            &.dim-box-9 {
                width: 100px;
                height: 100px;
                top: 183px;
                left: 326px;
            }
            &.dim-box-10 {
                width: 140px;
                height: 140px;
                top: 383px;
                left: 0;
            }
        }
        .custom-circle {
            .circle-content {
                .circle-icon {
                    max-width: 50px;
                    height: auto;
                }
                h4 {
                    font-size: 14px;
                    line-height: 1.2;
                }
            }
            &.circle-1,
            &.circle-2,
            &.circle-6,
            &.circle-7,
            &.circle-9 {
                width: 100px;
                height: 100px;

            }
            &.circle-3,
            &.circle-8,
            &.circle-10 {
                width: 140px;
                height: 140px;
            }
            &.circle-4,
            &.circle-5 {
                width: 145px;
                height: 145px;
            }
        }
    }
    @media(max-width:490px) {
        max-width: 320px;
        width: 320px;
        height: 500px;
        margin: 0 auto;
        .circle-background {
            .bg-circle-1 {
                width: 180px;
                height: 180px;
                top: 20px;
                left: 20px;
            }
            .bg-circle-2 {
                width: 140px;
                height: 140px;
                top: 300px;
                left: 0;
            }
            .bg-circle-3 {
                width: 160px;
                height: 160px;
                top: 180px;
                left: 170px;
            }
            .bg-circle-4,
            .bg-circle-5 {
                display: none;
            }
        }
        .circle-box {
            &.dim-box-1 {
                width: 75px;
                height: 75px;
                top: 0;
                left: 90px;
            }
            &.dim-box-2 {
                width: 75px;
                height: 75px;
                top: 30px;
                left: 0;
            }
            &.dim-box-3 {
                width: 120px;
                height: 120px;
                top: 86px;
                left: 79px;
            }
            &.dim-box-4 {
                width: 125px;
                height: 125px;
                top: 12px;
                left: 202px;;
            }
            &.dim-box-5 {
                width: 125px;
                height: 125px;
                top: 232px;
                left: 211px;
            }
            &.dim-box-6 {
                width: 75px;
                height: 75px;
                top: 354px;
                left: 149px;
            }
            &.dim-box-7 {
                width: 75px;
                height: 75px;
                top: 148px;
                left: 3px;
            }
            &.dim-box-8 {
                width: 120px;
                height: 120px;
                top: 226px;
                left: 71px;
            }
            &.dim-box-9 {
                width: 75px;
                height: 75px;
                top: 148px;
                left: 215px;
            }
            &.dim-box-10 {
                width: 120px;
                height: 120px;
                top: 340px;
                left: 0;
            }
        }
        .custom-circle {
            .circle-content {
                .circle-icon {
                    max-width: 35px;
                }
                h4 {
                    font-size: 10px;
                    line-height: 1.2;
                }
            }
            &.circle-1,
            &.circle-2,
            &.circle-6,
            &.circle-7,
            &.circle-9 {
                width: 75px;
                height: 75px;

            }
            &.circle-3,
            &.circle-8,
            &.circle-10 {
                width: 120px;
                height: 120px;
            }
            &.circle-4,
            &.circle-5 {
                width: 125px;
                height: 125px;
            }
        }
    }
`

export default CircleRow