import React, { useEffect }  from "react"
import styled from 'styled-components'

import WLChat from '../../images/lion.svg'
import UserChat from '../../images/user-avatar.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const ChatBox = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        let tlChat = gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: "#section_two_animate",
                start: 'top 160px',
                end: '800px 160px',
                id: 'start chat',
                toggleActions: "play pause resume restart",
                },
            })
        // how can we help
        tlChat.to('.ai-chat-1', { duration: .5, maxHeight: '60px' }, .5)
        tlChat.to('.ai-dots-1', { duration: .5, opacity: '1', visibility: 'visible' }, 1)
        tlChat.to('.ai-dots-1', { duration: .1, display: 'none' }, 1.8)
        tlChat.to('.ai-chat-1-message', { duration: .1, width: 'auto', padding: '0 20px' }, 2)
        tlChat.to('.ai-chat-1-message', { duration: .1, opacity: '1', visibility: 'visible' }, 2.1)
        // I need help...
        tlChat.to('.ai-chat-2', { duration: .5, maxHeight: '60px' }, 2.5)
        tlChat.to('.ai-dots-2', { duration: .5, opacity: '1', visibility: 'visible' }, 3)
        tlChat.to('.ai-dots-2', { duration: .1, display: 'none' }, 3.8)
        tlChat.to('.ai-chat-2-message', { duration: .1, width: 'auto', padding: '0 20px' }, 4)
        tlChat.to('.ai-chat-2-message', { duration: .1, opacity: '1', visibility: 'visible' }, 4.1)
        tlChat.to('.ai-chat-2', { duration: .3, maxHeight: '120px' }, 4.5)
        tlChat.to('.ai-chat-3-message', { duration: .1, width: 'auto', padding: '0 20px' }, 4.7)
        tlChat.to('.ai-chat-3-message', { duration: .1, opacity: '1', visibility: 'visible' }, 4.8)
        // Absolutely!
        tlChat.to('.ai-chat-4', { duration: .5, maxHeight: '60px' }, 6)
        tlChat.to('.ai-dots-4', { duration: .5, opacity: '1', visibility: 'visible' }, 6.5)
        tlChat.to('.ai-dots-4', { duration: .1, display: 'none' }, 7.3)
        tlChat.to('.ai-chat-4', { duration: .3, maxHeight: '105px' }, 7.5)
        tlChat.to('.ai-chat-4-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 7.6)
        tlChat.to('.ai-chat-4-message', { duration: .1, opacity: '1', visibility: 'visible' }, 7.7)
        // What does that mean?
        tlChat.to('.ai-chat-5', { duration: .5, maxHeight: '60px' }, 8.5)
        tlChat.to('.ai-dots-5', { duration: .5, opacity: '1', visibility: 'visible' }, 9)
        tlChat.to('.ai-dots-5', { duration: .1, display: 'none' }, 9.8)
        tlChat.to('.ai-chat-5-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 10)
        tlChat.to('.ai-chat-5-message', { duration: .1, opacity: '1', visibility: 'visible' }, 10.1)
        // custom built apps
        tlChat.to('.ai-chat-6', { duration: .5, maxHeight: '60px' }, 11)
        tlChat.to('.ai-dots-6', { duration: .5, opacity: '1', visibility: 'visible' }, 11.2)
        tlChat.to('.ai-dots-6', { duration: .1, display: 'none' }, 11.8)
        tlChat.to('.ai-chat-6-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 12)
        tlChat.to('.ai-chat-6-message', { duration: .1, opacity: '1', visibility: 'visible' }, 12.1)
        // software
        tlChat.to('.ai-chat-6', { duration: .5, maxHeight: '120px' }, 12.5)
        tlChat.to('.ai-dots-6', { duration: .5, opacity: '1', visibility: 'visible', display: 'block' }, 12.7)
        tlChat.to('.ai-dots-6', { duration: .1, display: 'none' }, 13.3)
        tlChat.to('.ai-chat-7-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 13.5)
        tlChat.to('.ai-chat-7-message', { duration: .1, opacity: '1', visibility: 'visible' }, 13.6)
        // cloud
        tlChat.to('.ai-chat-6', { duration: .5, maxHeight: '180px' }, 14)
        tlChat.to('.ai-dots-6', { duration: .5, opacity: '1', visibility: 'visible', display: 'block' }, 14.2)
        tlChat.to('.ai-dots-6', { duration: .1, display: 'none' }, 14.8)
        tlChat.to('.ai-chat-8-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 15)
        tlChat.to('.ai-chat-8-message', { duration: .1, opacity: '1', visibility: 'visible' }, 15.1)
        // artificial
        tlChat.to('.ai-chat-6', { duration: .5, maxHeight: '240px' }, 15.5)
        tlChat.to('.ai-dots-6', { duration: .5, opacity: '1', visibility: 'visible', display: 'block' }, 15.7)
        tlChat.to('.ai-dots-6', { duration: .1, display: 'none' }, 16.3)
        tlChat.to('.ai-chat-9-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 16.5)
        tlChat.to('.ai-chat-9-message', { duration: .1, opacity: '1', visibility: 'visible' }, 16.6)
        // API
        tlChat.to('.ai-chat-6', { duration: .5, maxHeight: '300px' }, 17)
        tlChat.to('.ai-dots-6', { duration: .5, opacity: '1', visibility: 'visible', display: 'block' }, 17.2)
        tlChat.to('.ai-dots-6', { duration: .1, display: 'none' }, 17.8)
        tlChat.to('.ai-chat-10-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 18)
        tlChat.to('.ai-chat-10-message', { duration: .1, opacity: '1', visibility: 'visible' }, 18.1)
        // great!
        tlChat.to('.ai-chat-11', { duration: .5, maxHeight: '60px' }, 18.5)
        tlChat.to('.ai-dots-11', { duration: .5, opacity: '1', visibility: 'visible' }, 18.7)
        tlChat.to('.ai-dots-11', { duration: .1, display: 'none' }, 19.3)
        tlChat.to('.ai-chat-11-message', { duration: .1, width: 'auto', padding: '15px 20px' }, 19.5)
        tlChat.to('.ai-chat-11-message', { duration: .1, opacity: '1', visibility: 'visible' }, 19.6)

    }, []);

    return(
        <ChatMain>
            <div class="ai-chat-1 ai-chat-row ai-wl">
                <img src={WLChat} class="ai-chat-1-icon ai-wl-icon" alt="WL chat Icon" height="50" width="50" />
                <div class="ai-chat-1-message black-message">
                    <p>Hi! How can we help?</p>
                </div>
                <div class="dot-pulse ai-dots-1"></div>
            </div>
            <div class="ai-chat-2 ai-chat-row ai-user">
                <img src={UserChat} class="ai-chat-2-icon ai-user-icon" alt="User chat Icon" height="50" width="50" />
                <div class="ai-chat-2-message gray-message">
                    <p>I need help creating a custom digital experience.</p>
                </div>
                <div class="ai-chat-3-message gray-message">
                    <p>Can you do that?</p>
                </div>
                <div class="dot-pulse ai-dots-2"></div>
            </div>
            <div class="ai-chat-4 ai-chat-row ai-wl">
                <img src={WLChat} class="ai-chat-4-icon ai-wl-icon" alt="WL chat Icon" height="50" width="50" />
                <div class="ai-chat-4-message black-message">
                    <p>Absolutely! We build custom enterprise-level applications that power critical business systems.</p>
                </div>
                <div class="dot-pulse ai-dots-4"></div>
            </div>
            <div class="ai-chat-5 ai-chat-row ai-user">
                <img src={UserChat} class="ai-chat-5-icon ai-user-icon" alt="User chat Icon" height="50" width="50" />
                <div class="ai-chat-5-message gray-message">
                    <p>What on Earth does that mean?</p>
                </div>
                <div class="dot-pulse ai-dots-5"></div>
            </div>
            <div class="ai-chat-6 ai-chat-row ai-wl">
                <img src={WLChat} class="ai-chat-6-icon ai-wl-icon" alt="WL chat Icon" height="50" width="50" />
                <div class="ai-chat-6-message black-message">
                    <p>Custom Built Applications</p>
                </div>
                <div class="ai-chat-7-message black-message">
                    <p>Software Integrations</p>
                </div>
                <div class="ai-chat-8-message black-message">
                    <p>Cloud Solutions</p>
                </div>
                <div class="ai-chat-9-message black-message">
                    <p>Artificial Intelligence</p>
                </div>
                <div class="ai-chat-10-message black-message">
                    <p>API Development & Integrations</p>
                </div>
                <div class="dot-pulse ai-dots-6"></div>
            </div>
            <div class="ai-chat-11 ai-chat-row ai-user">
                <img src={UserChat} class="ai-chat-11-icon ai-user-icon" alt="User chat Icon" height="50" width="50" />
                <div class="ai-chat-11-message gray-message">
                    <p>Great! I'll take one of each!</p>
                </div>
                <div class="dot-pulse ai-dots-11"></div>
            </div>
        </ChatMain>
    );

}

const ChatMain = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .ai-chat-row {
        width: 100%;
        max-height: 0;
        padding: 0 80px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        overflow: hidden;
        .ai-user-icon {
            position: absolute;
            right: 20px;
            top: 0;
        }
        .ai-wl-icon {
            position: absolute;
            left: 20px;
            top: 0;
        }
        &.ai-user {
            justify-content: flex-end;
            .dot-pulse {
                position: absolute;
                left: -9662px;
                bottom: 30px;
                opacity: 0;
                visibility: hidden;
            }
            .gray-message {
                min-height: 50px;
                width: 0;
                background-color: #eaeaea;
                margin-bottom: 10px;
                border-radius: 25px;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                p {
                    font-family: "Work Sans";
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 400;
                    color: #2b2520;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }
        &.ai-wl {
            justify-content: flex-start;
            .dot-pulse {
                position: absolute;
                left: -9887px;
                bottom: 29px;
                opacity: 0;
                visibility: hidden;
            }
            .black-message {
                min-height: 50px;
                width: 100%;
                background-color: #2b2520;
                margin-bottom: 10px;
                border-radius: 25px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                p {
                    font-family: "Work Sans";
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    color: #fff;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    @media(max-width:767px) {
        .ai-chat-row {
            &.ai-user {
                padding-left: 15px;
                padding-right: 50px;
                .ai-user-icon {
                    width: 40px;
                    height: 40px;
                    right: 7px;
                }
                .dot-pulse {
                    display: none !important;
                    visibility: hidden;
                }
                .gray-message {
                    p {
                        font-size: 13px;
                    }
                }
            }
            &.ai-wl {
                padding-left: 50px;
                padding-right: 15px;
                .dot-pulse {
                    display: none !important;
                    visibility: hidden;
                }
                .ai-wl-icon {
                    width: 40px;
                    height: 40px;
                    left: 7px;
                }
                .black-message {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
`

export default ChatBox