import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import OurWorkSlider from './our-work-slider'

import GrayDots from '../../images/gray-halftone-dots.svg'

const OurWork = () => {

    return(
        <MainSection id="our_work_animate">
            <div class="our-work-title-bar">
                <div class="ow-title-box">
                    <h2 class="ow-title-h2">Our Work</h2>
                </div>
            </div>
            <div class="our-work-slider">
                <OurWorkSlider />
            </div>
        </MainSection>
    );

}

const MainSection = styled.section`
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    .our-work-title-bar {
        max-width: 1310px;
        width: 100%;
        margin: 0px auto;
        margin-bottom: 100px;
        padding: 0 20px;
        padding-top: 120px;
        position: relative;
        left: -150%;
        &:before {
            content: '';
            position: absolute;
            height: calc(100% - 120px);
            width: 200%;
            top: 120px;
            left: calc(-200% + 20px);
            background-color: #db4140;
        }
        .ow-title-box {
            width: 100%;
            height: 150px;
            padding-right: 150px;
            position: relative;
            background-color: #db4140;
            &:before {
                content: '';
                position: absolute;
                top: -120px;
                right: 55px;
                height: 120px;
                width: 200%;
                background-image: url(${GrayDots});
                background-size: 10%;
            }
        }
        h2 {
            position: absolute;
            font-family: "Work Sans";
            font-size: 50px;
            line-height: 150px;
            font-weight: 700;
            letter-spacing: 5px;
            color: #fff;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            right: -100%;
        }
    }
    .our-work-slider {
        width: 100%;
        top: 20px;
        opacity: 0;
    }
    @media(max-width: 1200px) {
        .our-work-title-bar {
            left: auto !important;
            .ow-title-box {
                &:before {
                    background-size: 20%;
                }
            }
            h2 {
                right: 150px !important;
            }
        }
        .our-work-slider {
            top: 0 !important;
            opacity: 1 !important;
        }
    }
    @media(max-width: 767px) {
        .our-work-title-bar {
            .ow-title-box {
                padding-right: 0;
                &:before {
                    background-size: 40%;
                }
            }
            h2 {
                position: relative;
                right: auto !important;
                text-align: center;
                font-size: 24px;
            }
        }
    }
`

export default OurWork