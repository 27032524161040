import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import ComputerSlider from './computer-slider'

import SmallArrow from '../../svgs/small-arrow.svg'
import UXIcon from '../../svgs/experience-creators.svg'
import UserExperience from '../../images/user-experience.svg'
import BgLine from '../../images/background-line01.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const ExperienceCreators = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({

            "(min-width: 1200px)": function() {
                // computer animation
                let tlEcOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#computer_animate",
                        start: 'top 80%',
                        end: 'top 80%',
                        id: 'computer-animate',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlEcOne.to('.ec-bottom-row', { duration: .5, opacity: '1', top: '0px' })
                tlEcOne.to('.computer-full', { duration: .3, maxWidth: '746px', maxHeight: '57px' })
                tlEcOne.add('compFull')
                tlEcOne.to('.ec-white-dot', { duration: .3, opacity: '1' }, 'compFull')
                tlEcOne.to('.computer-full', { duration: .3, maxHeight: '445px', borderRadius: '0%' }, 'compFull')

                // Our Work Entrance
                let tlEcTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#our_work_animate",
                        start: 'top 80%',
                        end: 'top 80%',
                        id: 'our_work_animate',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlEcTwo.to('.our-work-title-bar', { duration: 1, left: '0' }, 0)
                tlEcTwo.to('.ow-title-h2', { duration: 1, right: '150px' }, 0.4)
                tlEcTwo.to('.our-work-slider', { duration: .5, opacity: '1', top: '0' }, 0.5)
            },
            "(max-width: 1200px)": function() {
                let ecMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ec_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ec_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ecMobileOne.to('.ec-header', { duration: .5, opacity: '1', top: '0' })

                let ecMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ec_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ec_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ecMobileTwo.to('.ec-content', { duration: .5, opacity: '1', top: '0' })

                let ecMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#our_work_animate",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ec_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ecMobileThree.to('.ec-bottom-row', { duration: .5, opacity: '1' })

            }

        });
    
    }, []);

    return(
        <MainSection id="section_six_animation">
            <div class="top-row">
                <div class="ec-content"  id="ec_mobile_two">
                    <div class="ux-icon">
                        <UXIcon/>
                    </div>
                    <h3>User Experience & Design</h3>
                    <p>Experience Marketing is designed to make every touchpoint with your brand seamless and memorable. Give your audience the opportunity to interact with you in a way they could never do with traditional self- promoting marketing.</p>
                    <Link to="/services/experience-design/">Learn More <SmallArrow/></Link>
                </div>
                <div class="ec-header-box" id="ec_mobile_one">
                    <div class="ec-header-animation">
                        <div class="ec-red-box"></div>
                    </div>
                    <div class="ec-header">
                        <h2>Experience Creators</h2>
                        <p>Designing online experiences customers love.</p>
                    </div>
                </div>
            </div>
            <div class="ec-bottom-row" id="computer_animate">
                <img src={UserExperience} class="user-experience" alt="User Experience Graphic" height="642" width="870" />
                <div class="computer-screen-box">
                    <div class="computer-full">
                        <div class="computer-top-bar">
                            <div class="ec-white-dot"></div>
                            <div class="ec-white-dot"></div>
                            <div class="ec-white-dot"></div>
                        </div>
                        <div class="computer-main">
                            <ComputerSlider />
                        </div>
                    </div>
                </div>
                <img src={BgLine} class="bg-line" alt="Background Lines" height="418" width="362" />
            </div>
        </MainSection>
    );

}


const MainSection = styled.section`
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0;
    .top-row {
        max-width: 1310px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .ec-header-box {
            width: 704px;
            height: 500px;
            position: relative;
            .ec-header-animation {
                position: absolute;
                right: 0;
                top: -405px;
                width: 900px;
                height: 455px;
                .ec-red-box {
                    position: absolute;
                    width: 270px;
                    height: 270px;
                    border-radius: 50%;
                    background-color: #db4140;
                    opacity: 0;
                    right: 620px;
                    bottom: 180px;
                }
            }
        }
        .ec-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            width: 100%;
            margin-left: auto;
            overflow: hidden;
            opacity: 0;
            position: relative;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .ec-content {
            padding-left: 70px;
            max-width: 580px;
            width: 100%;
            position: relative;
            top: 20px;
            opacity: 0;
            .ux-icon {
                margin-top: 40px;
                margin-bottom: 60px;
                height: 163px;
                width: 163px;
            }
            h3 {
                font-family: "Work Sans";
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 10px;
            }
            p {
                font-family: "Work Sans";
                font-size: 18px;
                line-height: 36px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0px;
            }
            a {
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 10px;
                margin-bottom: 10px;
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition-duration: .3s;
                svg {
                    width: 24px;
                    margin-left: 10px;
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                    color: #db4140 !important;
                    svg {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .ec-bottom-row {
        max-width: 1310px;
        width: 100%;
        margin: 100px auto;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        opacity: 0;
        top: 20px;
        .computer-screen-box {
            position: absolute;
            width: 750px;
            height: 447px;
            top: 54px;
            left: 99px;
            background-color: #fff;
            .computer-full {
                position: absolute;
                width: 746px;
                height: 445px;
                top: 1px;
                left: 1px;
                max-width: 0;
                max-height: 0;
                border-radius: 15px;
                overflow: hidden;
                .computer-top-bar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 746px;
                    height: 57px;
                    border-radius: 15px 15px 0 0;
                    background-color: #2b2520;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .ec-white-dot {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin: 0 7px;
                        background-color: #fff;
                        opacity: 0;
                    }
                }
                .computer-main {
                    position: absolute;
                    top: 65px;
                    left: 0;
                    width: 746px;
                    height: 380px;
                    background-color: #db4140;
                }
            }
        }
    }
    @media(max-width: 1330px) {
        .ec-bottom-row {
            .bg-line {
                display: none;
            }
            .user-experience {
                margin-right: auto;
            }
            .computer-screen-box {
                left: 80px;
            }
        }
    }
    @media(max-width: 1200px) {
        .top-row {
            .ec-header-box {
                width: auto;
                height: auto;
            }
            .ec-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                top: 40px;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
        }
        .ec-bottom-row {
            .computer-screen-box {
                .computer-top-bar {
                    .ec-white-dot {
                        opacity: 1 !important;
                    }
                }
                .computer-full {
                    max-width: 100% !important;
                    max-height: 100% !important;
                }
            }
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        margin: 100px auto;
        .top-row {
            flex-wrap: wrap;
            .ec-header-box {
                order: 1;
                width: 100%;
                .ec-header {
                    width: 100%;
                    h2 {
                        font-size: 44px;
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
            .ec-content {
                order: 2;
                padding-left: 0;
                max-width: 520px;
                margin: 0 auto;
                margin-top: 60px;
                margin-bottom: 40px;
                .ux-icon {
                    display: none;
                }
            }
        }
        .ec-bottom-row {
            margin-top: 0;
            .user-experience {
                display: none;
            }
            .computer-screen-box {
                position: relative;
                width: 100%;
                height: 447px;
                top: 0;
                left: 0px;
                .computer-full {
                    .computer-top-bar {
                        width: 100%;
                    }
                    .computer-main {
                        width: 100%;
                        .computer-slider {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        .top-row {
            .ec-header-box {
                .ec-header {
                    padding: 20px !important;
                    border: 5px solid #db4140 !important;
                }
            }
            .ec-content {
                margin-top: 40px;
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                }
                p {
                    font-size: 16px;
                    line-height: 1.6;
                }
                a {
                    font-size: 18px;
                }
            }
        }
    }
`

export default ExperienceCreators