import React, { useEffect }  from "react"
import styled from 'styled-components'

import CEIcon from '../../svgs/icon-customer-experiences.svg'
import VRIcon from '../../svgs/icon-ar-vr-experiences.svg'
import AIIcon from '../../svgs/icon-ai-experiences.svg'
import BSIcon from '../../svgs/icon-brand-strategy.svg'
import CSIcon from '../../svgs/icon-content-strategy.svg'
import EIcon from '../../svgs/icon-engagement.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const SquaresRow = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({

            "(min-width: 1200px)": function() {
                // fade squares and transition

                let tlSrOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#square_row_transition",
                        start: '700px 80%',
                        end: '700px 80%',
                        id: 'square_row_transition',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                //tlSrOne.to('.diagonal-0', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-2', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-3', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-4', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-5', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-6', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                //tlSrOne.to('.diagonal-7', { duration: .5, opacity: '0', top: '-10px'  }, 0 )
                tlSrOne.to('.square-1 .square-content', { duration: .3, opacity: '0', top: '-40px'  }, 0 )
                tlSrOne.to('.diagonal-1', { duration: .3, top: '300px', left: '158px', width: '50px', height: '50px'  }, 0 )
                tlSrOne.to('.dim-red-square', { duration: .1, opacity: '1' })
                tlSrOne.to('.diagonal-1', { duration: .1, opacity: '0' })
                tlSrOne.to('.dim-red-square', { duration: .3, ease: "power1.inOut", bottom: '0', left: '0', rotation: 180  })
                tlSrOne.to('.dim-header', { duration: .1, opacity: '1' })
                tlSrOne.to('.dim-red-square', { duration: .1, opacity: '0' })
                tlSrOne.to('.dim-header', { maxHeight: '500px', maxWidth: '704px', padding: '50px 55px', border: '10px solid #db4140'  })
                tlSrOne.to('.dim-content', { duration: .5, opacity: '1', top: '0px' })
            }

        });        

    }, []);

    return(
        <MainRow id="square_row_transition" className={"square-row-mobile"}>
            <div class="square-0 tilted-square">
                <div class="diagonal diagonal-0"></div>
            </div>
            <div class="square-1 tilted-square">
                <div class="diagonal diagonal-1"></div>
                <div class="square-content">
                    <div class="sr-icon sr-ce-icon">
                        <CEIcon/>
                    </div>
                    <h4>Customer Experiences</h4>
                </div>
            </div>
            <div class="square-2 tilted-square">
                <div class="diagonal diagonal-2"></div>
                <div class="square-content">
                    <div class="sr-icon sr-vr-icon">
                        <VRIcon/>
                    </div>
                    <h4>AR/VR Experiences</h4>
                </div>
            </div>
            <div class="square-3 tilted-square">
                <div class="diagonal diagonal-3"></div>
                <div class="square-content">
                    <div class="sr-icon sr-ai-icon">
                        <AIIcon/>
                    </div>
                    <h4>AI Experiences</h4>
                </div>
            </div>
            <div class="square-4 tilted-square">
                <div class="diagonal diagonal-4"></div>
                <div class="square-content">
                    <div class="sr-icon sr-bs-icon">
                        <BSIcon/>
                    </div>
                    <h4>Brand Strategy</h4>
                </div>
            </div>
            <div class="square-5 tilted-square">
                <div class="diagonal diagonal-5"></div>
                <div class="square-content">
                    <div class="sr-icon sr-cs-icon">
                        <CSIcon/>
                    </div>
                    <h4>Content Strategy</h4>
                </div>
            </div>
            <div class="square-6 tilted-square">
                <div class="diagonal diagonal-6"></div>
                <div class="square-content">
                    <div class="sr-icon sr-e-icon">
                        <EIcon/>
                    </div>
                    <h4>Engagement</h4>
                </div>
            </div>
            <div class="square-7 tilted-square">
                <div class="diagonal diagonal-7"></div>
            </div>
            <div class="mobile-square square-8 tilted-square">
                <div class="diagonal diagonal-8"></div>
            </div>
            <div class="mobile-square square-9 tilted-square">
                <div class="diagonal diagonal-9"></div>
            </div>
            <div class="mobile-square square-10 tilted-square">
                <div class="diagonal diagonal-10"></div>
            </div>
            <div class="mobile-square square-11 tilted-square">
                <div class="diagonal diagonal-11"></div>
            </div>
        </MainRow>
    );

}

const MainRow = styled.div`
    max-width: 1300px;
    width: 100%;
    height: 555px;
    padding: 0;
    margin: 0 auto;
    position: relative;
    .mobile-square {
        display: none;
    }
    .tilted-square {
        position: absolute;
        width: 370px;
        height:370px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        .diagonal {
            position: absolute;
            top: 58px;
            left: 58px;
            width: 250px;
            height: 250px;
            transform: rotate(45deg);
        }
        .square-content {
            position: relative;
            text-align: center;
            max-width: 150px;
            .sr-icon {
                margin: 0 auto;
            }
            .sr-ce-icon {
                width: 71px;
                height: 63px;
            }
            .sr-vr-icon {
                height: 53px;
                width: 48px;
            }
            .sr-ai-icon {
                height: 45px;
                width: 48px;
            }
            .sr-bs-icon {
                height: 59px;
                width: 47px;
            }
            .sr-cs-icon {
                height: 46px;
                width: 46px;
            }
            .sr-e-icon {
                height: 59px;
                width: 63px;
            }
            h4 {
                margin-top: 20px;
                margin-bottom: 0;
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #fff;
            }
        }
    }
    .square-0 {
        top: 225px;
        left: -180px;
        .diagonal {
            background-color: #e2e2e2;
        }
    }
    .square-1 {
        top: 40px;
        left: 5px;
        .diagonal {
            background-color: #db4140;
        }
    }
    .square-2 {
        top: 225px;
        left: 190px;
        .diagonal {
            background-color: #2b2520;
        }
    }
    .square-3 {
        top: 40px;
        left: 375px;
        .diagonal {
            background-color: #db4140;
        }
    }
    .square-4 {
        top: 225px;
        left: 560px;
        .diagonal {
            background-color: #2b2520;
        }
    }
    .square-5 {
        top: 40px;
        left: 745px;
        .diagonal {
            background-color: #db4140;
        }
    }
    .square-6 {
        top: 225px;
        left: 930px;
        .diagonal {
            background-color: #2b2520;
        }
    }
    .square-7 {
        top: 40px;
        left: 1115px;
        .diagonal {
            background-color: #e2e2e2;
        }
    }
    @media(max-width: 1000px) {
        width: 700px;
        max-width: 700px;
        height: 470px;
        margin: 0 auto;
        .mobile-square {
            display: block;
        }
        .tilted-square {
            width: 233px;
            height: 233px;
            .diagonal {
                height: 165px;
                width: 165px;
                left: 34px;
                top: 34px;
            }
            .square-content {
                max-width: 120px;
                .sr-icon {
                    max-width: 50px;
                    height: auto;
                }
                h4 {
                    font-size: 14px;
                    line-height: 1.2;
                    margin-top: 10px;
                }
            }
        }
        .square-0,
        .square-7 {
            display: none;
        }
        .square-1 {
            top: 0px;
            left: 0px;
        }
        .square-2 {
            top: 233px;
            left: 0px;
        }
        .square-3 {
            top: 0px;
            left: 233px;
        }
        .square-4 {
            top: 233px;
            left: 233px;
        }
        .square-5 {
            top: 0px;
            left: 466px;
        }
        .square-6 {
            top: 233px;
            left: 466px;
        }
        .square-8 {
            top: 117px;
            left: -117px;
            .diagonal {
                background-color: #e2e2e2;
                border: 5px solid #fff;
            }
        }
        .square-9 {
            top: 117px;
            left: 116px;
            .diagonal {
                background-color: #e2e2e2;
                border: 5px solid #fff;
            }
        }
        .square-10 {
            top: 117px;
            left: 350px;
            .diagonal {
                background-color: #e2e2e2;
                border: 5px solid #fff;
            }
        }
        .square-11 {
            top: 117px;
            left: 583px;
            .diagonal {
                background-color: #e2e2e2;
                border: 5px solid #fff;
            }
        }
    }
    @media(max-width: 767px) {
        width: 500px;
        max-width: 500px;
        height: 350px;
        .tilted-square {
            width: 166px;
            height: 166px;
            .diagonal {
                height: 118px;
                width: 118px;
                left: 24px;
                top: 24px;
            }
            .square-content {
                max-width: 90px;
                .sr-icon {
                    max-width: 45px;
                    height: auto;
                }
                h4 {
                    font-size: 13px;
                    line-height: 1.2;
                    margin-top: 0px;
                }
            }
        }
        .square-2 {
            top: 167px;
            left: 0px;
        }
        .square-3 {
            left: 167px;
        }
        .square-4 {
            top: 167px;
            left: 167px;
        }
        .square-5 {
            top: 0px;
            left: 334px;
        }
        .square-6 {
            top: 167px;
            left: 334px;
        }
        .square-8 {
            top: 84px;
            left: -84px;
        }
        .square-9 {
            top: 84px;
            left: 84px;
        }
        .square-10 {
            top: 84px;
            left: 251px;
        }
        .square-11 {
            top: 84px;
            left: 417px;
        }
    }
    @media(max-width: 520px) {
        width: 320px;
        max-width: 320px;
        height: 350px;
        .tilted-square {
            width: 133px;
            height: 133px;
            .diagonal {
                height: 94px;
                width: 94px;
                left: 20px;
                top: 20px;
            }
            .square-content {
                max-width: 60px;
                .sr-icon {
                    max-width: 30px;
                }
                h4 {
                    font-size: 9px;
                }
            }
        }
        .square-1 {
            left: -40px;
        }
        .square-2 {
            top: 134px;
            left: -40px;
        }
        .square-3 {
            left: 94px;
        }
        .square-4 {
            top: 134px;
            left: 94px;
        }
        .square-5 {
            top: 0px;
            left: 228px;
        }
        .square-6 {
            top: 134px;
            left: 228px;
        }
        .square-8 {
            top: 67px;
            left: -107px;
        }
        .square-9 {
            top: 67px;
            left: 27px;
        }
        .square-10 {
            top: 67px;
            left: 161px;
        }
        .square-11 {
            top: 67px;
            left: 294px;
        }
    }
`

export default SquaresRow