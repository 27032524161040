import React from "react"

import Layout from "../components/layout-v2"
import SEO from "../components/seo"

import MovingTitle from "../components/home-components/moving-title-v2"
import SectionOneV2 from "../components/home-components/section-one-v2"
import AiIntegrators from "../components/home-components/ai-integrators"
import DataMiners from "../components/home-components/data-miners"
import TechnologyTrends from "../components/home-components/technology-trends"
import DigitalMarketing from "../components/home-components/digital-marketing"
import ExperienceCreators from "../components/home-components/experience-creators"
import OurWork from "../components/home-components/our-work"
import ContactUs from "../components/contact-us"

const IndexPage = ({ data: { pageContent } }) => {

    const content = pageContent.edges

    return(
        <Layout>
            {content.map(page => ( 
                <SEO 
                title={page.node.SEOmeta.metaTitle} 
                description={page.node.SEOmeta.metaDescription}
                keywords={page.node.SEOmeta.metaKeywords}
                ogTitle={page.node.SEOmeta.ogTitle} 
                ogDescription={page.node.SEOmeta.ogDescription}
                ogImage={page.node.SEOmeta.ogImage.localFile.childImageSharp.fluid}
                twitterTitle={page.node.SEOmeta.twitterTitle} 
                twitterDescription={page.node.SEOmeta.twitterDescription}
                twitterImage={page.node.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
                />
            ))}
            <MovingTitle />
            <SectionOneV2 />
            <AiIntegrators />
            <DataMiners />
            <TechnologyTrends />
            <DigitalMarketing />
            <ExperienceCreators />
            <OurWork />
            <ContactUs />
        </Layout>
    );

}

export default IndexPage

export const pageQuery = graphql`
    query {
        pageContent: allWpCustomPage(filter: {databaseId: {eq: 1444}}) {
            edges {
              node {
                SEOmeta {
                    metaTitle
                    metaDescription
                    metaKeywords
                    ogTitle
                    ogDescription
                    ogImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
              }
            }
          }
    }
`