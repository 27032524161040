import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import SmallArrow from '../../svgs/small-arrow.svg'
import DAIcon from '../../svgs/digital-architects.svg'
import Phone from '../../svgs/phone.svg'

import PhoneSlider from './phone-slider'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

const SectionOneV2 = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
            gsap.registerPlugin(MotionPathPlugin)
            gsap.core.globals('MotionPath', MotionPathPlugin)
        }

        // box intros
        gsap.to(".h-slide-up-1", { delay: 1, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-2", { delay: 1.1, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-3", { delay: 1.2, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-4", { delay: 1.3, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-5", { delay: 1.4, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-6", { delay: 1.5, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-7", { delay: 1.6, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-8", { delay: 1.7, duration: 1, opacity: '1', top: '0'});
        gsap.to(".h-slide-up-9", { delay: 1.8, duration: 1, opacity: '1', top: '0'});
        gsap.to(".home-header-main-copy", { delay: 2, duration: 1, opacity: '1', left: '0'});

        ScrollTrigger.matchMedia({
            "(min-width: 1201px)": function() {
                // red box moves and creates section title
                    
                let tlOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#red_box_animate",
                        start: 'top 36%',
                        end: 'top 36%',
                        id: 'section_one_animate',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlOne.to('.red-block-test', { duration: .5, ease: "power4.inOut", rotation: 405 })
                tlOne.to('.da-red-box', { duration: .1, ease: "power4.out", opacity: '1' }, 0.5)
                tlOne.to('.red-block-test', { duration: .1, opacity: '0' }, 0.6)
                tlOne.to('.da-red-box', { duration: .3, ease: "power1.inOut", bottom: '-50px', left: '0px', rotation: 180 }, 0.7)
                tlOne.to('.da-header', { duration: .1, opacity: '1' })
                tlOne.to('.da-red-box', { duration: .1, opacity: '0' })
                tlOne.to('.da-header', { maxHeight: '500px', maxWidth: '704px', padding: '50px 55px', border: '10px solid #db4140' })
                tlOne.add('daContentAppear')
                tlOne.to('.da-icon', { duration: .3, opacity: '1', top: '0' }, 'daContentAppear')
                tlOne.to('#phone_box ', { duration: .3, opacity: '1', top: '0' }, 'daContentAppear')
                tlOne.to('.da-content', { duration: .3, opacity: '1', top: '0' }, 'daContentAppear')

                // Phone screen shrinks to square, square moves to section two and creates new title
                let tlTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_two_animate",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'phone_animate',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlTwo.to('.phone-slider .slick-slider', { duration: .1, opacity: '0' })
                tlTwo.to('.phone-slider', { duration: .1, width: '50px', height: '50px' })
                tlTwo.to('.phone-red-animation', { duration: .1, opacity: '1' })
                tlTwo.to('.phone-slider', { duration: .1, opacity: '0' })
                //tlTwo.to('.da-phone', { duration: .3, opacity: '0' })
                tlTwo.to('.phone-red-animation', { duration: .3, ease: "power1.inOut", top: '480px', left: '160px', rotation: 180 })
                tlTwo.to('.ai-header', { duration: .1, opacity: '1' })
                tlTwo.to('.phone-red-animation', { duration: .1, opacity: '0' })
                tlTwo.to('.ai-header', { maxHeight: '370px', maxWidth: '930px', padding: '50px 55px', border: '10px solid #db4140' })
                tlTwo.to('.ai-logo-box', { duration: .3, opacity: '1', left: '-200px' })
                //tlTwo.to('.da-content', { duration: .2, opacity: '0', top: '20px' })
                //tlTwo.to('.da-icon', { duration: .2, opacity: '0', top: '20px' })
            },
            "(max-width: 1200px)": function() {
                let daMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#da_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'da_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                daMobileOne.to('.da-mobile-one', { duration: .5, opacity: '1', top: '0' })
                daMobileOne.to('.da-icon', { duration: .5, opacity: '1', top: '0' })

                let daMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#da_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'da_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                daMobileTwo.to('.da-content', { duration: .5, opacity: '1', top: '0' })

                let daMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#phone_box",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'da_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                daMobileThree.to('#phone_box', { duration: .5, opacity: '1', top: '0' })
            }
        });

    }, []);

    return(
        <RedBoxAnimate id="red_box_animate">
            <HeroBox className={"hero-fix"}>
                <div class="grid-row-main">
                    <div class="main-grid">
                        <div class="grid-row">
                            <div class="red-block h-slide-up-1">
                                <div class="red-block-test"></div>
                            </div>
                            <div class="h-slide-up-2"></div>
                            <div class="h-slide-up-3"></div>
                        </div>
                        <div class="grid-row">
                            <div class="h-slide-up-4"></div>
                            <div class="h-slide-up-5"></div>
                            <div class="h-slide-up-6"></div>
                        </div>
                        <div class="grid-row">
                            <div class="h-slide-up-7"></div>
                            <div class="h-slide-up-8"></div>
                            <div class="h-slide-up-9"></div>
                        </div>
                    </div>
                    <div class="home-header-main-copy">
                        <h2>Digital Experiences Brought to Life</h2>
                        <div class="hero-links">
                            <Link to="/services/intelligence">Intelligence</Link>
                            <Link to="/services/strategy">Strategy</Link>
                            <Link to="/services/design-development">Design & Development</Link>
                            <Link to="/services/custom-applications">Custom Applications</Link>
                            <Link to="/services/experience-design">Experience & Design</Link>
                            <Link to="/services/marketing-performance">Marketing & Performance</Link>
                        </div>
                    </div>
                </div>
            </HeroBox>
            <MainSection id="section_one_pin">
            <div class="da-animation-path">
                <div class="da-red-box"></div>
            </div>
            <div class="left-col">
                <div class="animation-block da-mobile-one" id="da_mobile_one">
                    <div class="da-header">
                        <h2>Digital Architects</h2>
                        <p>Solving complex web builds for 25 years</p>
                    </div>
                </div>
                <div class="da-content"  id="da_mobile_two">
                    <h3>Design & Development</h3>
                    <p>We've been building exceptional websites since the very beginning. With over 25 years of experience, our team of interactive designers and web developers excel in helping brands shape their ideas into engaging web experiences.</p>
                    <Link to="/services/design-development">Learn More <SmallArrow/></Link>
                </div>
            </div>
            <div class="right-col">
                <div class="da-icon">
                    <DAIcon />
                </div>
                <div id="phone_box">
                    <div class="phone-slider-placeholder">
                        <PhoneSlider />
                    </div>
                    <div class="phone-animation-path">
                        <div class="phone-red-animation"></div>
                    </div>
                    <div class="da-phone">
                        <Phone/>
                    </div>
                </div>
            </div>
        </MainSection>
        </RedBoxAnimate>  
    );

}

const RedBoxAnimate = styled.div`
    margin-top: 380px;
    @media(max-width:1200px) {
        margin-top: 0px;
        width: 100%;
    }
`

const HeroBox = styled.section`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 40px;
    .grid-row-main {
        max-width: 1310px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 10;
        .main-grid {
            height: 310px;
            width: 310px;
            margin-left: 60px;
            margin-right: 80px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            .grid-row {
                position: relative;
                width: 100%;
                max-width: 310px;
                height: 50px;
                display: flex;
                justify-content: space-between;
                > div {
                    height: 50px;
                    width: 50px;
                    background-color: #2b2520;
                    position: relative;
                    top: 40px;
                    opacity: 0;
                    &.red-block {
                        background-color: transparent;
                    }
                    .red-block-test {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50px;
                        height: 50px;
                        background-color: #db4140;
                        &.active {
                            top: -2px;
                            transform: rotate(405deg);
                            box-shadow: 4px 4px 8px rgb(0 0 0 / 20%);
                        }
                    }
                }
            }
        }
        .home-header-main-copy {
            opacity: 0;
            position: relative;
            left: 40px;
            h2 {
                font-family: "Work Sans";
                font-size: 40px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0;
            }
            .hero-links {
                width: 100%;
                max-width: 630px;
                display: flex;
                flex-wrap: wrap;
                a {
                    font-family: "Work Sans";
                    font-size: 24px;
                    font-weight: 700;
                    color: #db4140;
                    margin-right: 45px;
                    margin-top: 40px;
                }
            }
        }
    }
    @media(max-width: 1200px) {
        .grid-row-main {
            .main-grid {
                height: 270px;
                width: 270px;
                margin-left: 20px;
                margin-right: 60px;
            }
        }
    } 
    @media(max-width:1000px) {
        overflow: hidden;
        .grid-row-main {
            flex-wrap: wrap;
            .main-grid {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 150px;
                height: 150px;
                .grid-row {
                    height: 30px;
                    > div {
                        height: 30px;
                        width: 30px;
                        .red-block-test {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
            .home-header-main-copy {
                width: 100%;
                h2 {
                    text-align: center;
                }
                .hero-links {
                    max-width: 100%;
                    justify-content: center;
                    a {
                        margin-top: 20px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    @media(max-width:767px) {
        .grid-row-main {
            .main-grid {
                display: none;
                height: 100px;
                width: 100px;
                .grid-row {
                    height: 20px;
                    > div {
                        height: 20px;
                        width: 20px;
                        .red-block-test {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
            .home-header-main-copy {
                h2 {
                    font-size: 24px;
                }
                .hero-links {
                    a {
                        font-size: 18px;
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-top: 10px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
`

const MainSection = styled.section`
    position: relative;
    max-width: 1310px;
    width: 100%;
    margin: 100px auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    .da-animation-path {
        position: absolute;
        top: -524px;
        left: 20px;
        width: 70px;
        height: 524px;
        .da-red-box {
            position: absolute;
            height: 50px;
            width: 50px;
            bottom: 400px;
            left: 60px;
            background-color: #db4140;
            transform: rotate(45deg);
            opacity: 0;
        }
    }
    .left-col {
        width: 704px;
        .animation-block {
            height: 500px;
        }
        .da-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .da-content {
            margin-top: 160px;
            padding-left: 70px;
            max-width: 580px;
            opacity: 0;
            position: relative;
            top: 20px;
            h3 {
                font-family: "Work Sans";
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 10px;
            }
            p {
                font-family: "Work Sans";
                font-size: 18px;
                line-height: 36px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0px;
            }
            a {
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 10px;
                margin-bottom: 10px;
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition-duration: .3s;
                svg {
                  width: 24px;
                  margin-left: 10px;
                  transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                  color: #db4140 !important;
                  svg {
                    transform: translateX(10px);
                  }
                }
            }
        }
    }
    .right-col {
        width: 566px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .da-icon {
            position: relative;
            top: 20px;
            opacity: 0;
            svg {
                width: 150px;
                height: 149px;
                path {
                    fill: #eaeaea;
                }
                rect {
                    fill: #eaeaea;
                }
            }
        }
        #phone_box {
            height: 658px;
            width: 338px;
            position: relative;
            top: 20px;
            opacity: 0;
            .phone-slider-placeholder {
                width: 244px;
                height: 149px;
                background-color: #fff;
                opacity: 1;
                position: absolute;
                bottom: 339px;
                right: 45px;
                z-index: 10;
                overflow: hidden;
            }
            .phone-animation-path {
                position: absolute;
                bottom: -150px;
                right: -114px;
                height: 540px;
                width: 210px;
                z-index: 11;
                .phone-red-animation {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: 0;
                    left: 0;
                    background-color: #db4140;
                    opacity: 0;
                }  
            }
        }
        .da-phone {
            height: 658px;
            width: 338px;
            position: relative;
        }
    }
    @media(max-width: 1200px) {
        .da-mobile-one,
        .da-icon {
            position: relative;
            opacity: 0;
            top: 40px;
        }
        .left-col {
            .da-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                opacity: 1 !important;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
            
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        .left-col {
            width: 100%;
            .animation-block {
                height: auto;
            }
            .da-header {
                h2 {
                    font-size: 44px;
                }
                p {
                    font-size: 20px;
                }
            }
            .da-content {
                padding-left: 0;
                max-width: 520px;
                margin: 0 auto;
                margin-top: 60px;
            }
        }
        .right-col {
            width: 100%;
            .da-icon {
                display: none;
            }
            #phone_box {
                margin-top: 30px;
            }
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        .left-col {
            .da-header {
                padding: 20px !important;
                border: 5px solid #db4140 !important;
            }
            .da-content {
                margin-top: 40px;
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                }
                p {
                    font-size: 16px;
                    line-height: 1.6;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .right-col {
            .da-phone {
                height: auto;
                width: 260px;
            }
            #phone_box {
                height: 507px;
                width: 260px;
                .phone-slider-placeholder {
                    width: 187px;
                    height: 113px;
                    bottom: 262px;
                    right: 35px;
                }
                .phone-animation-path {
                    display: none;
                }
            }
        }
    }
`

export default SectionOneV2