import React, { Component } from "react"
import Slider from "react-slick"
import styled from 'styled-components'

import LeftArrow from '../../images/left-arrow.png'
import RightArrow from '../../images/right-arrow.png'

class PhoneSlider extends Component {

    render() {
    
          const settings = {
            arrows: true,
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        
          return (
            <SliderContainer className={"phone-slider"}>
              <Slider {...settings}>
                <PhoneSlide>
                    <h4>WordPress</h4>
                </PhoneSlide>
                <PhoneSlide>
                    <h4>PHP Development</h4>
                </PhoneSlide>
                <PhoneSlide>
                    <h4>Custom CMS Tools</h4>
                </PhoneSlide>
                <PhoneSlide>
                    <h4>Ecommerce</h4>
                </PhoneSlide>
                <PhoneSlide>
                    <h4>ChatBot Solutions</h4>
                </PhoneSlide>
                <PhoneSlide>
                    <h4>Hosting, QA and Support</h4>
                </PhoneSlide>    
              </Slider>
            </SliderContainer>
          );
      }

}

const SliderContainer = styled.div`
  position: absolute;
  height: 149px;
  width: 244px;
  bottom: 0;
  right: 0;
  background-color: #db4140;
  .slick-prev {
      width: 15px;
      height: 24px;
      left: 5px;
      background-image: url(${LeftArrow});
      background-size: contain;
      background-position: center;
      background-repeat: none;
      color: transparent;
      z-index: 15;
      &:before {
          display: none;
      }
      &:hover {
        background-image: url(${LeftArrow});
      }
  }
    .slick-next {
        width: 15px;
        height: 24px;
        right: 5px;
        background-image: url(${RightArrow});
        background-size: contain;
        background-position: center;
        background-repeat: none;
        color: transparent;
        z-index: 15;
        &:before {
            display: none;
        }
        &:hover {
        background-image: url(${RightArrow});
        }
    }
    .slick-dots {
        bottom: 0;
        z-index: 15;
        li {
            opacity: .5;
            button {
                width: 10px;
                height: 10px;
                background-color: #fff;
                border-radius: 50%;
                margin: 0 auto;;
                &:before {
                    display: none;
                }
            }
            &.slick-active {
                opacity: 1;
            }
            &:hover {
                opacity: .8;
            }
        }
    }
    @media(max-width:767px) {
        width: 187px !important;
        height: 113px !important;
    }
`

const PhoneSlide = styled.div`
    width: 100%;
    height: 149px;
    h4 {
        height: 149px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-align: center;
        color: #fff;
        padding: 0 30px;
        font-family: "Work Sans";
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
    }
    @media(max-width:767px) {
        height: 113px !important;
        h4 {
            height: 113px !important;
            font-size: 16px;
            line-height: 1.2;
        }
    }
`






export default PhoneSlider