import React, { Component } from "react"
import Slider from "react-slick"
import styled from 'styled-components'

import IDicon from '../../svgs/uxd-identity-design.svg'
import MarketingIcon from '../../svgs/uxd-marketing.svg'
import EmailIcon from '../../svgs/uxd-email.svg'
import TemplatesIcon from '../../svgs/uxd-templates.svg'
import VideoIcon from '../../svgs/uxd-video.svg'
import MotionIcon from '../../svgs/uxd-motion-graphics.svg'

import LeftArrow from '../../images/left-arrow.png'
import RightArrow from '../../images/right-arrow.png'

class ComputerSlider extends Component {

    render() {
    
          const settings = {
            arrows: true,
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
          };
        
          return (
            <SliderContainer className={"computer-slider"}>
              <Slider {...settings}>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-id-icon">
                                    <IDicon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Identity Design</h4>
                        </div>
                    </div>
                </ComputerSlide>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-marketing-icon">
                                    <MarketingIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Marketing Collateral</h4>
                        </div>
                    </div>
                </ComputerSlide>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-email-icon">
                                    <EmailIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Email</h4>
                        </div>
                    </div>
                </ComputerSlide>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-templates-icon">
                                    <TemplatesIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Custom Templates & Themes</h4>
                        </div>
                    </div>
                </ComputerSlide>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-video-icon">
                                    <VideoIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Video</h4>
                        </div>
                    </div>
                </ComputerSlide>
                <ComputerSlide>
                    <div>
                        <div class="icon-box">
                            <div class="icon-red">
                                <div class="uxd-icon uxd-motion-icon">
                                    <MotionIcon/>
                                </div>
                            </div>
                        </div>
                        <div class="icon-title-box">
                            <h4>Motion Graphics</h4>
                        </div>
                    </div>
                </ComputerSlide>    
              </Slider>
            </SliderContainer>
          );
      }

}

const SliderContainer = styled.div`
  position: absolute;
  width: 746px;
  height: 380px;
  top: 0;
  left: 0;
  background-color: #db4140;
  .slick-slider {
      padding: 0 50px;
  }
  .slick-prev {
      width: 15px;
      height: 24px;
      left: 10px;
      background-image: url(${LeftArrow});
      background-size: contain;
      background-position: center;
      background-repeat: none;
      color: transparent;
      z-index: 15;
      &:before {
          display: none;
      }
      &:hover {
        background-image: url(${LeftArrow});
      }
  }
    .slick-next {
        width: 15px;
        height: 24px;
        right: 10px;
        background-image: url(${RightArrow});
        background-size: contain;
        background-position: center;
        background-repeat: none;
        color: transparent;
        z-index: 15;
        &:before {
            display: none;
        }
        &:hover {
        background-image: url(${RightArrow});
        }
    }
    .slick-dots {
        bottom: 0;
        left: 0;
        z-index: 15;
        li {
            opacity: .5;
            button {
                width: 10px;
                height: 10px;
                background-color: #fff;
                border-radius: 50%;
                margin: 0 auto;;
                &:before {
                    display: none;
                }
            }
            &.slick-active {
                opacity: 1;
            }
            &:hover {
                opacity: .8;
            }
        }
    }
`

const ComputerSlide = styled.div`
    width: 100%;
    height: 350px;
    padding: 50px 20px;
    outline: 0;
    > div {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .icon-box {
            height: 180px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-red {
                height: 100px;
                width: 100px;
                background-color: #db4140;
                display: flex;
                justify-content: center;
                align-items: center;
                .uxd-icon {
                    margin: 0 auto;
                }
                .uxd-id-icon {
                    height: 72px;
                    width: 72px;
                }
                .uxd-marketing-icon {
                    height: 75px;
                    width: 75px;
                }
                .uxd-email-icon {
                    height: 72px;
                    width: 75px;
                }
                .uxd-templates-icon {
                    height: 75px;
                    width: 75px;
                }
                .uxd-video-icon {
                    height: 66px;
                    width: 75px;
                }
                .uxd-motion-icon {
                    height: 75px;
                    width: 75px;
                }
            }
        }
        .icon-title-box {
            height: 70px;
            background-color: #2b2520;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    h4 {
        margin: 0;
        text-align: center;
        color: #fff;
        padding: 0 30px;
        font-family: "Work Sans";
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }
`






export default ComputerSlider