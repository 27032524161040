import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import SmallArrow from '../../svgs/small-arrow.svg'
import DataMinersIcon from '../../svgs/data-miners.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const DataMiners = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({
            "(min-width: 1201px)": function() {
                // Animate in content and graphs
                let tlDmOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_three_animate",
                        start: '400px 80%',
                        end: '400px 80%',
                        id: 'section_three_animate',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlDmOne.to('.data-miners-icon', { duration: .3, opacity: '1', top: '0px' }, 0)
                tlDmOne.to('.dm-content', { duration: .3, opacity: '1', top: '0px' }, 0)
                tlDmOne.to('.graph-1', { duration: .5, ease: "power1.inOut", height: '251px' }, 0.3)
                tlDmOne.to('.graph-1-h4', { duration: .2, opacity: '1' })
                tlDmOne.to('.graph-2', { duration: .5, ease: "power1.inOut", height: '221px' }, 0.6)
                tlDmOne.to('.graph-2-h4', { duration: .2, opacity: '1' })
                tlDmOne.to('.graph-3', { duration: .5, ease: "power1.inOut", height: '328px' }, 0.9)
                tlDmOne.to('.graph-3-h4', { duration: .2, opacity: '1' })
                tlDmOne.to('.graph-4', { duration: .5, ease: "power1.inOut", height: '290px' }, 1.2)
                tlDmOne.to('.graph-4-h4', { duration: .2, opacity: '1' })
                tlDmOne.to('.graph-5', { duration: .5, ease: "power1.inOut", height: '441px' }, 1.5)
                tlDmOne.to('.graph-5-h4', { duration: .2, opacity: '1' })
                tlDmOne.to('.graph-6', { duration: .5, ease: "power1.inOut", height: '513px' }, 1.8)
                tlDmOne.to('.graph-6-h4', { duration: .2, opacity: '1' })

                // collapse bars, red square to next section
                let tlDmTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_four_animate",
                        start: '100px 80%',
                        end: '100px 80%',
                        id: 'bar_graph_close',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlDmTwo.to('.graph-red-animation', { duration: .1, opacity: '1' })
                tlDmTwo.to('.graph-1-h4', { duration: .2, opacity: '0' }, 0.2)
                tlDmTwo.to('.graph-1', { duration: .3, ease: "power1.inOut", height: '40px' }, 0.2)
                tlDmTwo.to('.graph-1', { duration: .1, opacity: '0' })
                //tlDmTwo.to('.bar-graphs', { duration: .5, maxHeight: '0px' })
                tlDmTwo.to('.graph-red-animation', { duration: .3, ease: "power1.inOut", width: '50px', height: '50px', top: '198px', left: '397px', rotation: 180 })
                tlDmTwo.to('.tt-header', { duration: .1, opacity: '1' })
                tlDmTwo.to('.graph-red-animation', { duration: .1, opacity: '0'})
                tlDmTwo.to('.tt-header', { duration: .3, maxHeight: '675px', maxWidth: '704px', padding: '50px 55px', border: '10px solid #db4140' })
                tlDmTwo.to('.tt-content', { duration: .3, opacity: '1', top: '0px' })
                //tlDmTwo.to('.data-miners-icon', { duration: .2, opacity: '0', top: '20px' })
                //tlDmTwo.to('.dm-content', { duration: .2, opacity: '0', top: '20px' })
            },
            "(max-width: 1200px)": function() {
                let dmMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dm_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dm_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dmMobileOne.to('.dm-header', { duration: .5, opacity: '1', top: '0' })

                let dmMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dm_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dm_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dmMobileTwo.to('.data-miners-icon', { duration: .5, opacity: '1', top: '0' })

                let dmMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dm_mobile_three",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dm_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dmMobileThree.to('.dm-content', { duration: .5, opacity: '1', top: '0' })

                let dmMobileFour = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dm_mobile_four",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dm_mobile_four',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dmMobileFour.to('.bar-graph-box', { duration: .5, opacity: '1', top: '0' })
            }
        });
    
    }, []);

    return(
        <MainSection id="section_three_animate">
            <div class="left-col">
                <div class="dm-header-box" id="dm_mobile_one">
                    <div class="dm-header">
                        <h2>Data Miners</h2>
                        <p>Turning the unstructured into actionable information.</p>
                    </div>
                </div>
                <div class="dm-content"  id="dm_mobile_three">
                    <h3>Intelligence</h3>
                    <p>User-centered digital experiences and targeted buyer journeys, we help you reach the right people at the right time to build brand awareness and drive value for your company.</p>
                    <Link to="/services/intelligence/">Learn More <SmallArrow/></Link>
                </div>
            </div>
            <div class="right-col">
                <div class="data-miners-icon">
                    <DataMinersIcon/>
                </div>
                <div class="bar-graph-box"  id="dm_mobile_four">
                    <div class="graph-animation">
                        <div class="graph-red-animation"></div>
                    </div>
                    <div class="bar-graphs" id="bar_graphs">
                        <div class="graph-1 graph">
                            <h4 class="graph-1-h4">Audience Personas</h4>
                        </div>
                        <div class="graph-2 graph">
                            <h4 class="graph-2-h4">Marketing Insights</h4>
                        </div>
                        <div class="graph-3 graph">
                            <h4 class="graph-3-h4">Sales Goals & KPIs</h4>
                        </div>
                        <div class="graph-4 graph">
                            <h4 class="graph-4-h4">User Research</h4>
                        </div>
                        <div class="graph-5 graph">
                            <h4 class="graph-5-h4">Design Thinking</h4>
                        </div>
                        <div class="graph-6 graph">
                            <h4 class="graph-6-h4">Measurement Plan</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-bottom"></div>
        </MainSection>
    );

}

const MainSection = styled.section`
    max-width: 1310px;
    width: 100%;
    margin: 100px auto;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    .border-bottom {
        height: 23px;
        width: 100%;
        background-color: #eaeaea;
        margin-top: 35px;
    }
    .left-col {
        width: 704px;
        .dm-header-box {
            height: 500px;
            width: 704px;
        }
        .dm-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            position: relative;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .dm-content {
            margin-top: 145px;
            margin-bottom: 90px;
            padding-left: 70px;
            max-width: 580px;
            position: relative;
            opacity: 0;
            top: 20px;
            h3 {
                font-family: "Work Sans";
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 10px;
            }
            p {
                font-family: "Work Sans";
                font-size: 18px;
                line-height: 36px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0px;
            }
            a {
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 10px;
                margin-bottom: 10px;
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition-duration: .3s;
                svg {
                    width: 24px;
                    margin-left: 10px;
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                    color: #db4140 !important;
                    svg {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .right-col {
        width: 466px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 90px;
        .data-miners-icon {
            position: relative;
            width: 230px;
            height: 230px;
            opacity: 0;
            top: 20px;
        }
        .bar-graph-box {
            width: 466px;
            height: 513px;
            position: relative;
            display: flex;
            align-content: flex-end;
            .graph-animation {
                position: absolute;
                bottom: -208px;
                right: 0;
                width: 448px;
                height: 248px;
                .graph-red-animation {
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    top: 0;
                    left: 0;
                    background-color: #db4140;
                    opacity: 0;
                }
            }
        }
        .bar-graphs {
            width: 466px;
            height: 513px;
            max-height: 513px;
            margin-top: auto;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            position: relative;
            overflow: hidden;
            > div.graph {
                width: 40px;
                background-color: #eaeaea;
                position: relative;
                h4 {
                    font-family: "Work Sans";
                    font-size: 20px;
                    line-height: 40px;
                    font-weight: 700;
                    color: #2b2520;
                    margin-top: 0;
                    margin-bottom: 0px;
                    margin: 0;
                    transform: rotate(-90deg);
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                    height: 300px;
                    width: 300px;
                    opacity: 0;
                }
                &.graph-1,
                &.graph-3,
                &.graph-5 {
                    background-color: #db4140;
                    h4 {
                        color: #fff;
                    }
                }
            }
        }
    }
    @media(max-width:1200px) {
        .left-col {
            width: 55%;
            .dm-header-box {
                height: auto;
                width: auto;
            } 
            .dm-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                top: 40px;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
        }
        .right-col {
            width: 45%;
            .bar-graph-box {
                height: auto;
                opacity: 0;
                top: 40px;
                position: relative;
            }
            .bar-graphs {
                margin-top: 40px;
                margin-bottom: 30px;
                .graph-1 {
                    height: 251px !important;;
                }
                .graph-2 {
                    height: 221px !important;;
                }
                .graph-3 {
                    height: 328px !important;;
                }
                .graph-4 {
                    height: 290px !important;;
                }
                .graph-5 {
                    height: 441px !important;;
                }
                .graph-6 {
                    height: 513px !important;;
                }
                h4 {
                    opacity: 1 !important;
                }
            }
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        .left-col {
            width: 100%;
            .dm-header {
                h2 {
                    font-size: 44px;
                }
                p {
                    font-size: 20px;
                }
            }
            .dm-content {
                margin: 0 auto;
                margin-top: 60px;
                margin-bottom: 40px;
                padding-left: 0;
                max-width: 520px;
            }
        }
        .right-col {
            width: 100%;
            padding-top: 0;
            .data-miners-icon {
                display: none;
            }
            .bar-graph-box {
                margin: 0 auto;
            }
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        .border-bottom {
            display: none;
        }
        .left-col {
            .dm-header {
                padding: 20px !important;
                border: 5px solid #db4140 !important;
            }
            .dm-content {
                margin-top: 40px;
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                }
                p {
                    font-size: 16px;
                    line-height: 1.6;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .right-col {
            .bar-graph-box {
                max-width: 450px;
                width: 100%;
                .bar-graphs {
                    height: auto;
                    margin-top: 0;
                    .graph-1 {
                        height: 221px !important;;
                    }
                    .graph-2 {
                        height: 191px !important;;
                    }
                    .graph-3 {
                        height: 248px !important;;
                    }
                    .graph-4 {
                        height: 220px !important;;
                    }
                    .graph-5 {
                        height: 271px !important;;
                    }
                    .graph-6 {
                        height: 303px !important;;
                    }
                    > div.graph {
                        width: 30px;
                        h4 {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
`

export default DataMiners