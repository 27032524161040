import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import CircleRow from './circle-row'

import SmallArrow from '../../svgs/small-arrow.svg'
import DMIcon from '../../svgs/digital-marketing.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const DigitalMarketing = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({

            "(min-width: 1201px)": function() {
                // animate circle entrance
                let tlDimOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_five_animate",
                        start: '700px 80%',
                        end: '700px 80%',
                        id: 'animate_circle_section',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlDimOne.to('.bg-circle', { duration: .5, opacity: '1' }, 0)
                tlDimOne.to('.dim-box-1', { duration: .1, opacity: '1' }, 0)
                tlDimOne.to('.dim-box-1', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 0)
                tlDimOne.to('.dim-box-7', { duration: .1, opacity: '1' }, 0.2)
                tlDimOne.to('.dim-box-7', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 0.2)
                tlDimOne.to('.dim-box-5', { duration: .1, opacity: '1' }, 0.6)
                tlDimOne.to('.dim-box-5', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 0.6)
                tlDimOne.to('.dim-box-2', { duration: .1, opacity: '1' }, 0.7)
                tlDimOne.to('.dim-box-2', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 0.7)
                tlDimOne.to('.dim-box-6', { duration: .1, opacity: '1' }, 1.1)
                tlDimOne.to('.dim-box-6', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 1.1)
                tlDimOne.to('.dim-box-8', { duration: .1, opacity: '1' }, 1.4)
                tlDimOne.to('.dim-box-8', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 1.4)
                tlDimOne.to('.dim-box-4', { duration: .1, opacity: '1' }, 1.8)
                tlDimOne.to('.dim-box-4', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 1.8)
                tlDimOne.to('.dim-box-3', { duration: .1, opacity: '1' }, 1.9)
                tlDimOne.to('.dim-box-3', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 1.9)
                tlDimOne.to('.dim-box-9', { duration: .1, opacity: '1' }, 2.4)
                tlDimOne.to('.dim-box-9', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 2.4)
                tlDimOne.to('.dim-box-10', { duration: .1, opacity: '1' }, 2.7)
                tlDimOne.to('.dim-box-10', { duration: .5, transform: 'scale(1)', ease: "elastic.out(1, 0.5)" }, 2.7)
            },
            "(max-width: 1200px)": function() {
                let dimMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dim_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dim_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dimMobileOne.to('.dim-header', { duration: .5, opacity: '1', top: '0' })

                let dimMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#dim_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dim_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dimMobileTwo.to('.dim-content', { duration: .5, opacity: '1', top: '0' })

                let dimMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#circle_close",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'dim_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                dimMobileThree.to('.circle-row-mobile', { duration: .5, opacity: '1' })

            }

        }); 
    
    }, []);

    return(
        <MainSection id="section_five_animate">
            <div class="top-row">
                <div class="dim-header-box" id="dim_mobile_one">
                    <div class="dim-header-animation">
                        <div class="dim-red-square"></div>
                    </div>
                    <div class="dim-header">
                        <h2>Digital Marketing</h2>
                        <p>Experienced digital marketing and web strategists.</p>
                    </div>
                </div>
                <div class="dim-content" id="dim_mobile_two">
                    <div class="digital-marketing-icon">
                        <DMIcon/>
                    </div>
                    <h3>Marketing & Performance</h3>
                    <p>Keeping your target audience at the center of everything we do is our guiding principle for success. By creating user-centered digital experiences and targeted buyer journeys, we help you reach the right people at the right time to build brand awareness and drive value for your company.</p>
                    <Link to="/services/marketing-performance/">Learn More <SmallArrow/></Link>
                </div>
            </div>
            <CircleRow />
        </MainSection>
    );

}


const MainSection = styled.section`
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0;
    .top-row {
        max-width: 1310px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .dim-header-box {
            width: 704px;
            height: 500px;
            position: relative;
            .dim-header-animation {
                position: absolute;
                width: 200px;
                height: 400px;
                top: -350px;
                left: 0;
                .dim-red-square {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    transform: rotate(45deg);
                    bottom: 355px;
                    left: 148px;
                    background-color: #db4140;
                    opacity: 0;
                }
            }
        }
        .dim-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            position: relative;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .dim-content {
            padding-left: 70px;
            max-width: 580px;
            width: 100%;
            position: relative;
            z-index: 10;
            top: 20px;
            opacity: 0;
            .digital-marketing-icon {
                margin-top: 40px;
                margin-bottom: 60px;
                height: 192px;
                width: 210px;
            }
            h3 {
                font-family: "Work Sans";
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 10px;
            }
            p {
                font-family: "Work Sans";
                font-size: 18px;
                line-height: 36px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0px;
            }
            a {
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 10px;
                margin-bottom: 10px;
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition-duration: .3s;
                svg {
                    width: 24px;
                    margin-left: 10px;
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                    color: #db4140 !important;
                    svg {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .middle-row {
        max-width: 1310px;
        width: 100%;
        margin: 100px auto;
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        a {
            display: inline-block;
            font-family: "Work Sans";
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #FFF;
            padding: 15px 30px;
            margin: 15px;
            background-color: #db4140;
            text-decoration: none;
            transition-duration: .3s;
            &:hover {
                background-color: #2b2520;
            }
        }
    }
    .bottom-row {
        height: 340px;
        width: 100%;
        background-color: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #2b2520;
    }
    @media(max-width:1200px) {
        .top-row {
            .dim-header-box {
                width: auto;
                height: auto;
            }
            .dim-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                top: 40px;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        overflow: hidden;
        margin: 0px auto;
        padding-bottom: 400px;
        position: relative;
        .top-row {
            flex-wrap: wrap;
            .dim-header-box {
                width: 100%;
                .dim-header {
                    width: 100%;
                    h2 {
                        font-size: 44px;
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
            .dim-content {
                max-width: 520px;
                padding-left: 0;
                margin: 0 auto;
                margin-top: 60px;
                margin-bottom: 60px;
                .digital-marketing-icon {
                    display: none;
                }
            }
        }
        #circle_close {
            position: absolute;
            bottom: -99px;
            left: -122px;
            transform: scale(.5);
            margin-top: 0;
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        padding-bottom: 50px;
        .top-row {
            .dim-header-box {
                .dim-header {
                    padding: 20px !important;
                    border: 5px solid #db4140 !important;
                }
            }
            .dim-content {
                margin-top: 40px;
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                }
                p {
                    font-size: 16px;
                    line-height: 1.6;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        #circle_close {
            position: relative;
            left: auto;
            bottom: auto;
            transform: scale(1);
        }
    }
`

export default DigitalMarketing