import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import ChatBoxAnimated from './chatbox'

import SmallArrow from '../../svgs/small-arrow.svg'
import { FaChevronRight } from 'react-icons/fa'
import WLIcon from '../../svgs/white-lion-icon.svg'
import AiIcon from '../../svgs/ai-icon.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const AiIntegrators = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        ScrollTrigger.matchMedia({
            "(min-width: 1201px)": function() {
                // Fade in content

                let tlAiOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#chatbox_enter",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'chatbox_enter',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlAiOne.to('.ai-chat-box', { duration: .5, opacity: '1', maxWidth: '470px', maxHeight: '54px' })
                tlAiOne.to('.ai-chat-box', { duration: .5, maxHeight: '500px' })
                tlAiOne.to('.ai-icon', { duration: .5, opacity: '1', top: '0px' })
                tlAiOne.to('.ai-content', { duration: .5, opacity: '1', top: '0px' })

                // close chatbox and move red block to next section
                let tlAiTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_three_animate",
                        start: 'top 80%',
                        end: 'top 80%',
                        id: 'chatbox_close',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlAiTwo.to('.chatbox-red-animation', { duration: .1, opacity: '1' })
                tlAiTwo.to('.ai-chat-box', { duration: .1, maxHeight: '0px' })
                tlAiTwo.to('.chatbox-red-animation', { duration: .3, ease: "power1.inOut", height: '50px', width: '50px', top: '634px', right: '490px', rotation: 180 })
                tlAiTwo.to('.dm-header', { duration: .1, opacity: '1' })
                tlAiTwo.to('.chatbox-red-animation', { duration: .1, opacity: '0' })
                tlAiTwo.to('.dm-header', { maxHeight: '500px', maxWidth: '704px', padding: '50px 55px', border: '10px solid #db4140' })
                //tlAiTwo.to('.ai-icon', { duration: .2, opacity: '0', top: '20px' })
                //tlAiTwo.to('.ai-content', { duration: .2, opacity: '0', top: '20px' })
            },
            "(max-width: 1200px)": function() {
                let aiMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ai_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ai_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                aiMobileOne.to('.ai-header', { duration: .5, opacity: '1', top: '0' })

                let aiMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ai_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ai_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                aiMobileTwo.to('.ai-icon', { duration: .5, opacity: '1', top: '0' })

                let aiMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ai_mobile_three",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ai_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                aiMobileThree.to('.ai-content', { duration: .5, opacity: '1', top: '0' })

                let aiMobileFour = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#ai_mobile_four",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'ai_mobile_four',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                aiMobileFour.to('.ai-chat-box', { duration: .5, opacity: '1', top: '0' })
            }
        });
    
    }, []);

    return(
        <MainSection id="section_two_animate">
            <div class="top-row">
                <div class="ai-animation-block" id="ai_mobile_one">
                    <div class="ai-header">
                        <h2>AI Integrators</h2>
                        <p>Bridging the gap between data science and DevOps.</p>
                    </div>
                    <div class="ai-logo-box">
                        <div class="wl-icon">
                            <WLIcon/>
                        </div>
                        <div class="black-1"></div>
                        <div class="gray-1"></div>
                        <div class="black-2"></div>
                        <div class="gray-2"></div>
                    </div>
                </div>
            </div>
            <div class="main-row" id="chatbox_enter">
                <div class="left-col">
                    <div class="chat-box-outer" id="chatbox_close">
                        <div class="ai-chat-box" id="ai_mobile_four">
                            <div class="chat-top-bar">
                                <div class="ai-white-dot"></div>
                                <div class="ai-white-dot"></div>
                                <div class="ai-white-dot"></div>
                            </div>
                            <div class="chat-message-window">
                                <ChatBoxAnimated />
                            </div>
                            <div class="chat-bottom-bar">
                                <div class="ai-message-input">
                                    <p>Message White Lion</p>
                                    <div class="send-button"><FaChevronRight size={32}/></div>
                                </div>
                            </div>
                        </div>
                        <div class="chatbox-animation-path">
                            <div class="chatbox-red-animation"></div>
                        </div>
                    </div>
                </div>
                <div class="right-col">
                    <div class="ai-icon" id="ai_mobile_two">
                        <AiIcon/>
                    </div>
                    <div class="ai-content" id="ai_mobile_three">
                        <h3>Custom Applications</h3>
                        <p>User-centric experiences that are engaging, responsive, and intuitive by integrating smart technologies into custom applications. These purpose-built apps are designed to help you make smarter decisions, give you better interactions with users, and drive faster adoption of your product and service.</p>
                        <Link to="/services/custom-applications">Learn More <SmallArrow/></Link>
                    </div>
                </div>
            </div>
        </MainSection>
    );

}

const MainSection = styled.section`
    max-width: 1310px;
    width: 100%;
    margin: 100px auto;
    padding: 0 20px;
    position: relative;
    .top-row {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 100px;
        .ai-animation-block {
            height: 370px;
            position: relative;
        }
        .ai-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            position: relative;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
                max-width: 600px;
            }
        }
        .ai-logo-box {
            position: absolute;
            top: 50px;
            left: -180px;
            width: 200px;
            height: 200px;
            opacity: 0;
            img {
                position: absolute;
                top: 0;
                right: 0;
            }
            div {
                position: absolute;
                width: 50px;
                height: 50px;
                &.wl-icon {
                    width: 100px;
                    height: 100px;
                    right: 0;
                    top: 0;
                }
                &.black-1 {
                    background-color: #2b2520;
                    top: 100px;
                    right: 0;
                }
                &.gray-1 {
                    background-color: #bcbec0;
                    top: 150px;
                    right: 50px;
                }
                &.black-2 {
                    background-color: #2b2520;
                    top: 100px;
                    right: 100px;
                }
                &.gray-2 {
                    background-color: #eaeaea;
                    top: 50px;
                    right: 150px;
                }
            }
        }
    }
    .main-row {
        width: 100%;
        padding-left: 70px;
        display: flex;
        justify-content: center;
        align-items: stretch;
        .left-col {
            width: 470px;
            .chat-box-outer {
                height: 500px;
                width: 470px;
                position: relative;
                .chatbox-animation-path {
                    position: absolute;
                    right: 0;
                    top: 54px;
                    width: 540px;
                    height: 684px;
                    .chatbox-red-animation {
                        position: absolute;
                        background-color: #db4140;
                        height: 378px;
                        width: 470px;
                        top: 0;
                        right: 0;
                        opacity: 0;
                    }
                }
            }
            .ai-chat-box {
                box-shadow: 0 0 20px rgb(0 0 0 / 16%);
                border-radius: 10px;
                position: relative;
                width: 470px;
                height: 500px;
                max-width: 0px;
                max-height: 0px;
                overflow: hidden;
                opacity: 0;
                .chat-top-bar {
                    height: 54px;
                    width: 100%;
                    background-color: #2b2520;
                    border-radius: 10px 10px 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 15px;
                    .ai-white-dot {
                        width: 15px;
                        height: 15px;
                        background-color: #fff;
                        border-radius: 50%;
                        margin: 0 7px;
                    }
                }
                .chat-message-window {
                    background-color: #db4140;
                    height: 378px;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                }
                .chat-bottom-bar {
                    height: 68px;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 0 0 10px 10px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .ai-message-input {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #eee;
                        border-radius: 40px;
                        p {
                            font-family: "Work Sans";
                            font-size: 16px;
                            line-height: 1;
                            font-weight: 400;
                            color: #2b2520;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-left: 20px;
                        }
                        .send-button {
                            height: 40px;
                            width: 40px;
                            background-color: #db4140;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 5px;
                            svg {
                                color: #fff;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
        .right-col {
            width: 780px;
            padding-left: 85px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            .ai-icon {
                margin-top: 60px;
                margin-bottom: 40px;
                width: 209px;
                height: 209px;
                position: relative;
                top: 20px;
                opacity: 0;
            }
            .ai-content {
                width: 100%;
                position: relative;
                top: 20px;
                opacity: 0;
                h3 {
                    font-family: "Work Sans";
                    font-size: 30px;
                    line-height: 36px;
                    font-weight: 400;
                    color: #2b2520;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
                p {
                    font-family: "Work Sans";
                    font-size: 18px;
                    line-height: 36px;
                    font-weight: 400;
                    color: #777c7c;
                    margin-top: 0;
                    margin-bottom: 0px;
                    max-width: 560px;
                }
                a {
                    font-family: "Work Sans";
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 700;
                    color: #2b2520;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    text-decoration: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    transition-duration: .3s;
                    svg {
                        width: 24px;
                        margin-left: 10px;
                        transition: all .3s cubic-bezier(.77,0,.175,1);
                    }
                    &:hover {
                        color: #db4140 !important;
                        svg {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }
    }
    @media(max-width:1200px) {
        .top-row {
            .ai-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                top: 40px;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
        }
        .main-row {
            .left-col {
                .ai-chat-box {
                    max-height: 100% !important;
                    max-width: 100% !important;
                    opacity: 0;
                    top: 40px;
                }
            }
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        .top-row {
            justify-content: center;
            margin-bottom: 60px;
            .ai-animation-block {
                height: auto;
                width: 100%;
            }
            .ai-header {
                width: 100%;
                h2 {
                    font-size: 44px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
        .main-row {
            padding-left: 0;
            flex-wrap: wrap;
            .left-col {
                order: 2;
                width: 100%;
                .chat-box-outer {
                    margin: 0 auto;
                }
            }
            .right-col {
                order: 1;
                width: 100%;
                padding-left: 0;
                .ai-icon {
                    display: none;
                }
                .ai-content {
                    max-width: 520px;
                    margin: 0 auto;
                    margin-bottom: 40px;
                }
            }
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        .top-row {
            margin-bottom: 0;
            .ai-header {
                padding: 20px !important;
                border: 5px solid #db4140 !important;
            }
        }
        .main-row {
            .right-col {
                .ai-content {
                    margin-top: 40px;
                    h3 {
                        font-size: 24px;
                        line-height: 1.2;
                    }
                    p {
                        font-size: 16px;
                        line-height: 1.6;
                    }
                    a {
                        font-size: 18px;
                    }
                }
            }
            .left-col {
                .chat-box-outer {
                    max-width: 470px;
                    width: 100%;
                    height: 410px;
                    .ai-chat-box {
                        max-width: 470px;
                        width: 100%;
                        height: 400px;
                        .chat-message-window {
                            height: 278px;
                        }
                    }
                }
            }
        }
    }
`

export default AiIntegrators