import React, { useEffect }  from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import SquaresRow from './squares-row'

import SmallArrow from '../../svgs/small-arrow.svg'
import TTIcon from '../../svgs/technology-trends.svg'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const TechnologyTrends = () => {

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        // animate in diagonal squares

        ScrollTrigger.matchMedia({

            "(min-width: 1201px)": function() {
                let tlTTOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#section_four_animate",
                        start: '600px 80%',
                        end: '600px 80%',
                        id: 'animate_diagonal_squares',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                tlTTOne.to('.square-0', { duration: .1, opacity: '1' }, 0 )
                tlTTOne.to('.square-0', { duration: 1, top: '185px', ease: "elastic.out(1.5, 0.5)" }, 0 )
                tlTTOne.to('.square-1', { duration: .1, opacity: '1' }, 0.4 )
                tlTTOne.to('.square-1', { duration: 1, top: '0px', ease: "elastic.out(1.5, 0.5)" }, 0.4 )
                tlTTOne.to('.square-2', { duration: .1, opacity: '1' }, 0.8 )
                tlTTOne.to('.square-2', { duration: 1, top: '185px', ease: "elastic.out(1.5, 0.5)" }, 0.8 )
                tlTTOne.to('.square-3', { duration: .1, opacity: '1' }, 1.2 )
                tlTTOne.to('.square-3', { duration: 1, top: '0px', ease: "elastic.out(1.5, 0.5)" }, 1.2 )
                tlTTOne.to('.square-4', { duration: .1, opacity: '1' }, 1.6 )
                tlTTOne.to('.square-4', { duration: 1, top: '185px', ease: "elastic.out(1.5, 0.5)" }, 1.6 )
                tlTTOne.to('.square-5', { duration: .1, opacity: '1' }, 2 )
                tlTTOne.to('.square-5', { duration: 1, top: '0px', ease: "elastic.out(1.5, 0.5)" }, 2 )
                tlTTOne.to('.square-6', { duration: .1, opacity: '1' }, 2.4 )
                tlTTOne.to('.square-6', { duration: 1, top: '185px', ease: "elastic.out(1.5, 0.5)" }, 2.4 )
                tlTTOne.to('.square-7', { duration: .1, opacity: '1' }, 2.8 )
                tlTTOne.to('.square-7', { duration: 1, top: '0px', ease: "elastic.out(1.5, 0.5)" }, 2.8 )
            },
            "(max-width: 1200px)": function() {
                let ttMobileOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#tt_mobile_one",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'tt_mobile_one',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ttMobileOne.to('.tt-header', { duration: .5, opacity: '1', top: '0' })

                let ttMobileTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#tt_mobile_two",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'tt_mobile_two',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ttMobileTwo.to('.tt-content', { duration: .5, opacity: '1', top: '0' })

                let ttMobileThree = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#square_row_transition",
                        start: 'top 90%',
                        end: 'top 90%',
                        id: 'tt_mobile_three',
                        toggleActions: "play play reverse reverse",
                        },
                    })
                ttMobileThree.to('.square-row-mobile', { duration: .5, opacity: '1', top: '0' })

            }

        });
    
    }, []);

    return(
        <MainSection id="section_four_animate">
            <div class="top-row">
                <div class="tt-content" id="tt_mobile_two">
                    <div class="tt-icon">
                        <TTIcon/>
                    </div>
                    <div>
                        <h3>Strategy</h3>
                        <p>Our data-led strategic roadmap transforms how your brand connects and interacts with your target audience and empowers your company to move in the right direction.</p>
                        <Link to="/services/strategy">Learn More <SmallArrow/></Link>
                    </div>
                </div>
                <div class="tt-header-box" id="tt_mobile_one">
                    <div class="tt-header">
                        <h2>Technology Trends</h2>
                        <p>Using machine learning to identify predictive trends.</p>
                    </div>
                </div>
                
            </div>
            <SquaresRow />
        </MainSection>
    );

}

const MainSection = styled.section`
    width: 100%;
    margin: 100px auto;
    overflow: hidden;
    .top-row {
        max-width: 1310px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 100px;
        .tt-content {
            width: 100%;
            max-width: 595px;
            padding-left: 70px;
            padding-right: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: relative;
            opacity: 0;
            top: 20px;
            .tt-icon {
                margin-top: 70px;
                margin-bottom: 40px;
                height: 187px;
                width: 204px;
            }
            h3 {
                font-family: "Work Sans";
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 10px;
            }
            p {
                font-family: "Work Sans";
                font-size: 18px;
                line-height: 36px;
                font-weight: 400;
                color: #777c7c;
                margin-top: 0;
                margin-bottom: 0px;
                max-width: 560px;
            }
            a {
                font-family: "Work Sans";
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 10px;
                margin-bottom: 10px;
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition-duration: .3s;
                svg {
                    width: 24px;
                    margin-left: 10px;
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                    color: #db4140 !important;
                    svg {
                        transform: translateX(10px);
                    }
                }
            }
        }
        .tt-header-box {
            height: 500px;
            width: 675px;
            display: flex;
            justify-content: flex-end;
        }
        .tt-header {
            border: 25px solid #db4140;
            padding: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            opacity: 0;
            position: relative;
            h2 {
                font-family: "Nobel",sans-serif;
                font-size: 120px;
                line-height: 130px;
                font-weight: 700;
                color: #2b2520;
                margin-top: 0;
                margin-bottom: 20px;
            }
            p {
                font-family: "Work Sans";
                font-size: 36px;
                line-height: 50px;
                font-weight: 400;
                color: #777C7C;
                margin-top: 0;
                margin-bottom: 0;
                max-width: 600px;
            }
        }
    }
    @media(max-width: 1200px) {
        .top-row {
            margin-bottom: 0;
            .tt-header-box {
                height: auto;
                width: auto;
            }
            .tt-header {
                border: 10px solid #db4140 !important;
                padding: 35px !important;
                max-height: 100% !important;
                max-width: 100% !important;
                overflow: hidden;
                top: 40px;
                h2 {
                    font-size: 80px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
        }
        .square-row-mobile {
            opacity: 0;
            top: 40px;
        }
        #square_row_transition {
            transform: scale(.7);
            margin-top: -50px;
            .tilted-square {
                opacity: 1 !important;
            }
        }
    }
    @media(max-width: 1000px) {
        flex-wrap: wrap;
        max-width: 700px;
        position: relative;
        overflow: hidden;
        .top-row {
            flex-wrap: wrap;
            .tt-header-box {
                order: 1;
                width: 100%;
                .tt-header {
                    width: 100%;
                    h2 {
                        font-size: 44px;
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
            .tt-content {
                order: 2;
                max-width: 520px;
                padding-left: 0;
                padding-right: 0;
                margin: 0 auto;
                margin-top: 60px;
                margin-bottom: 40px;
                .tt-icon {
                    display: none;
                }
            }
        }
        #square_row_transition {
            transform: scale(1);
            margin-top: 0;
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
        margin: 50px auto;
        .top-row {
            .tt-header-box {
                .tt-header {
                    padding: 20px !important;
                    border: 5px solid #db4140 !important;
                }
            }
            .tt-content {
                margin-top: 40px;
                h3 {
                    font-size: 24px;
                    line-height: 1.2;
                }
                p {
                    font-size: 16px;
                    line-height: 1.6;
                }
                a {
                    font-size: 18px;
                }
            }
        }
    }
`

export default TechnologyTrends