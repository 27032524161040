import React, { useEffect }  from "react"
import styled from 'styled-components'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const MovingTitleV2 = () => {

    // Randomize header
    const max = 3;
    const rand = Math.floor(Math.random() * max);

    useEffect(() => {

        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger)
            gsap.core.globals('ScrollTrigger', ScrollTrigger)
        }

        // Text flyin on page load

        gsap.to(".custom-fly-in", {
            delay: 1,
            duration: 2,
            ease: "back.out(3)",
            css: {left: 0, opacity: 1},
        });

        ScrollTrigger.matchMedia({
            "(min-width: 1201px)": function() {

                // Move text based on mouse location

                function moveText(event) {
                    let posX = -(event.pageX / 4)
                    gsap.to(".moving-h1", {
                    duration: 1,
                    css: {
                    left: posX
                    }
                });
                }

                window.addEventListener('mousemove', moveText);

                // Parallax animation, slow scroll up and fade

                let tlOne = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#hero_parallax_trigger",
                        start: 'top top',
                        end: 'bottom top',
                        scrub: true,
                        id: 'hero_parallax_move',
                        },
                    })
                tlOne.to('.hero-parallax', { top: '30vh' })

                let tlTwo = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: "#hero_parallax_trigger",
                        start: '5% top',
                        end: '50% top',
                        scrub: true,
                        id: 'hero_parallax_fade',
                        },
                    })
                tlTwo.to('.hero-parallax', { opacity: '0' })    

            }
        });
    
    }, []);

    return(
        <HeroMain id="hero_parallax_trigger">
            <div class="hero-parallax">
                <MovingText className="row-full">
                    <div class="custom-fly-in">
                        <h1 class={rand === 0 ? "moving-h1 rand-1" : rand === 1 ? "moving-h1 rand-2" : "moving-h1 rand-3"}><span class="option-1">Creativity + Coding</span><span class="option-2">Design + Development</span><span class="option-3">Experience + Engagement</span></h1>
                    </div>
                </MovingText>
            </div>
        </HeroMain> 
    );

}

const HeroMain = styled.section`
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    .hero-parallax {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;
    }
    @media(max-width: 1200px) {
        position: relative;
        height: auto;
        .hero-parallax {
            position: relative;
            height: auto;
        }
    }
`

const MovingText = styled.div`
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    margin-top: 220px;
    margin-bottom: 60px;
    overflow: visible;
    position: relative;
    .custom-fly-in {
        position: relative;
        height: 160px;
        left: 200%;
    }
    h1 {
        position: absolute;
        width: 200%;
        font-size: 200px;
        z-index: 1;
        margin-top: 0;
        margin-bottom: 0;
        transition: all 0s cubic-bezier(.25,.46,.45,.94);
        &:hover {
            cursor: default;
        }
        > span {
            display: none;
        }
        &.rand-1 {
            .option-1 {
                display: block;
                padding-left: 100px;
            }
        }
        &.rand-2 {
            .option-2 {
                display: block;
                padding-left: 100px;
            }
        }
        &.rand-3 {
            .option-3 {
                display: block;
                font-size: 160px;
                padding-left: 100px;
            }
        }
    }
    @media(max-width: 1600px) {
        h1 {
            &.rand-1 {
                .option-1 {
                    font-size: 160px;
                    padding-left: 200px;
                }
            }
            &.rand-2 {
                .option-2 {
                    font-size: 160px;
                }
            }
            &.rand-3 {
                .option-3 {
                    font-size: 140px;
                }
            }
        }
    }
    @media(max-width: 1400px) {
        h1 {
            &.rand-1 {
                .option-1 {
                    font-size: 140px;
                }
            }
            &.rand-2 {
                .option-2 {
                    font-size: 140px;
                    padding-left: 200px;
                }
            }
            &.rand-3 {
                .option-3 {
                    font-size: 120px;
                    padding-left: 200px;
                }
            }
        }
    }
    @media(max-width: 1200px) {
        margin-top: 20px;
        .custom-fly-in {
            height: auto;
            padding: 0 30px;
        }
        h1 {
            font-size: 120px;
            position: relative;
            left: auto;
            width: 100%;
            padding-left: 0;
            &.rand-1 {
                .option-1 {
                    font-size: 120px;
                    padding-left: 0px;
                }
            }
            &.rand-2 {
                .option-2 {
                    font-size: 120px;
                    padding-left: 0px;
                }
            }
            &.rand-3 {
                .option-3 {
                    font-size: 120px;
                    padding-left: 0px;
                }
            }
        }
    }
    @media(max-width: 1000px) {
        margin-top: 20px;
        h1 {
            font-size: 100px;
            text-align: center;
            &.rand-1 {
                .option-1 {
                    font-size: 100px;
                }
            }
            &.rand-2 {
                .option-2 {
                    font-size: 100px;
                }
            }
            &.rand-3 {
                .option-3 {
                    font-size: 100px;
                }
            }
        }
    }
    @media(max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 20px;
        .custom-fly-in {
            height: auto;
            h1 {
                font-size: 44px;
                padding-left: 20px;
                text-align: center;
                &.rand-1 {
                    .option-1 {
                        font-size: 44px;
                    }
                }
                &.rand-2 {
                    .option-2 {
                        font-size: 44px;
                    }
                }
                &.rand-3 {
                    .option-3 {
                        font-size: 44px;
                    }
                }
            }
        }
    }
`

export default MovingTitleV2